import { useState } from 'react';
import ReactGA from 'react-ga4';
// @types
import {
  CustomScheduleDayTimeEnum,
  CustomScheduleFrequencyEnum,
  CustomScheduleWeekTimeEnum,
  CustomScheduleSettings,
} from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// utils
import { displayToast } from 'src/utils/handleToast';
import { getAllTimeZones } from 'src/utils/getTimeZones';
// components
import { Toggle } from 'src/components/gravity/form';
import Alert from 'src/components/gravity/Alert';
//
import SettingsSection from '../SettingsSection';
import CustomScheduleForm from './CustomScheduleForm';

// ----------------------------------------------------------------------

export default function CustomScheduleSection() {
  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  // STATE
  const [currentScheduleSettings, setCurrentScheduleSettings] = useState<CustomScheduleSettings>({
    activate: true,
    settings: {
      frequency: CustomScheduleFrequencyEnum.WEEKLY,
      timeOfWeek: CustomScheduleWeekTimeEnum.WEEKDAYS,
      timeOfDay: CustomScheduleDayTimeEnum.MORNING,
      timeZone: getAllTimeZones()[0],
    },
  });

  const [isLoading, setisLoading] = useState<boolean>(false);

  const [saveCustomScheduleSuccess, setSaveCustomScheduleSuccess] = useState<boolean | undefined>(
    undefined
  );

  // EVENT FUNCTION
  async function handleToggleCustomSchedule(value: boolean) {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'toggle-custom-schedule',
    });

    setisLoading(true);
    try {
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve('Fetch successful');
          // reject('Fetch failed');
        }, 3000);
      });

      const isSuccess: boolean = true;

      if (value) {
        if (isSuccess) {
          displayToast(
            translate(
              'dashboard.sites.details.mwp.updates.updateSchedule.customScheduleSection.toast.activate.success'
            )
          );
          setCurrentScheduleSettings({
            activate: true,
            settings: {
              frequency: CustomScheduleFrequencyEnum.WEEKLY,
              timeOfWeek: CustomScheduleWeekTimeEnum.WEEKDAYS,
              timeOfDay: CustomScheduleDayTimeEnum.MORNING,
              timeZone: getAllTimeZones()[0],
            },
          });
        } else {
          displayToast(
            translate(
              'dashboard.sites.details.mwp.updates.updateSchedule.customScheduleSection.toast.activate.error'
            ),
            {
              variant: 'alert',
            }
          );
        }
      } else {
        if (isSuccess) {
          displayToast(
            translate(
              'dashboard.sites.details.mwp.updates.updateSchedule.customScheduleSection.toast.deactivate.success'
            )
          );
          setCurrentScheduleSettings((prev) => ({ ...prev, activate: value }));
        } else {
          displayToast(
            translate(
              'dashboard.sites.details.mwp.updates.updateSchedule.customScheduleSection.toast.deactivate.error'
            ),
            {
              variant: 'alert',
            }
          );
        }
      }
    } finally {
      setisLoading(false);
    }
  }

  return (
    <SettingsSection
      title={
        <div
          className="gv-flex-row-sm gv-items-center"
          style={{
            width: '100%',
            justifyContent: isMobile ? 'space-between' : 'flex-start',
          }}
        >
          <p className="gv-text-lg gv-text-bold">
            {translate(
              'dashboard.sites.details.mwp.updates.updateSchedule.customScheduleSection.title'
            )}
          </p>
          <Toggle
            labelId="activate"
            label={translate(
              'dashboard.sites.details.mwp.updates.updateSchedule.customScheduleSection.actionLabel'
            )}
            condensed
            disabled={isLoading}
            checked={currentScheduleSettings.activate}
            onChange={(e) => handleToggleCustomSchedule(e.currentTarget.checked)}
          />
        </div>
      }
      description={translate(
        'dashboard.sites.details.mwp.updates.updateSchedule.customScheduleSection.description'
      )}
      alert={
        saveCustomScheduleSuccess !== undefined ? (
          <Alert
            type={saveCustomScheduleSuccess ? 'success' : 'alert'}
            text={translate(
              `dashboard.sites.details.mwp.updates.updateSchedule.customScheduleSection.alert.${
                saveCustomScheduleSuccess ? 'success' : 'error'
              }`
            )}
            handleClose={() => setSaveCustomScheduleSuccess(undefined)}
          />
        ) : undefined
      }
    >
      {currentScheduleSettings.activate && (
        <CustomScheduleForm
          currentScheduleSettings={currentScheduleSettings}
          setCurrentScheduleSettings={setCurrentScheduleSettings}
          setSaveCustomScheduleSuccess={setSaveCustomScheduleSuccess}
        />
      )}
    </SettingsSection>
  );
}

import { NavLink as RouterLink, Outlet } from 'react-router-dom';
// config
import { HEADER } from 'src/config';

// ----------------------------------------------------------------------

export default function ErrorLayout() {
  return (
    <>
      <div className="gv-activated">
        <header className="gv-main-header">
          <div className="gv-header-nav">
            <div className="gv-nav-left">
              <RouterLink to="/" className="gv-logo">
                <gv-logo src="/src/logos/wp-one.svg" />
              </RouterLink>
            </div>
          </div>
        </header>
      </div>
      <div
        style={{
          paddingTop: `${HEADER.HEIGHT}px`,
        }}
      >
        <Outlet />
      </div>
    </>
  );
}

import { AxiosRequestHeaders } from 'axios';

// ----------------------------------------------------------------------

const REACT_APP_CHAT_BOT_API_KEY =
  'MDQ2ZGU4NjUtMjNiNC00ZTFkLWFkYTctMjk1ODY4ZTgxMDAzIDNhMTAzMmE3LWFjMWEtNDU1Mi05MmExLTAyYjBhMDg4MmFhOCA3MGJlMTU1NTkzZTExYmZiN2EzNTczOGRiYTVmMWU2YWNmNDFiYjAyYmFkZWMxNjMwYTNkMzBjNzZhYjQ5NzY0 ';

// ----------------------------------------------------------------------

export function createSignedAuthHeader(
  url: string,
  method: string,
  customHeaders: AxiosRequestHeaders
) {
  const apiKey = REACT_APP_CHAT_BOT_API_KEY || '';

  const timestamp = Date.now();

  const dataToSign = `${method}:${url}`;

  const headersString = JSON.stringify(customHeaders);
  const message = `${timestamp}:${dataToSign}:${headersString}`;

  const signedData = CryptoJS.HmacSHA256(message, apiKey).toString(CryptoJS.enc.Hex);

  const signatureObject = {
    apiKey,
    timestamp,
    signedData,
    headers: customHeaders,
  };

  const encodedSignature = btoa(JSON.stringify(signatureObject));
  const authHeader = `SHA256 ${encodedSignature}`;

  return {
    authHeader,
    signatureDetails: {
      original: signatureObject,
      base64Encoded: encodedSignature,
      fullHeader: authHeader,
      signatureComponents: {
        dataToSign,
        message,
        computedHmac: signedData,
      },
    },
  };
}

import { Dispatch, InputHTMLAttributes, SetStateAction } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
//
import { Autocomplete } from '../form';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  id: string;
  label: string;
  inputValue: string;
  selectedOption: string;
  isLoading: boolean;
  isError: boolean;
  options: string[];
  setInputValue: Dispatch<SetStateAction<string>>;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  handleInputOnChangeExtra?: (input: string) => void;
  handleSelectOptionChange: (input: string) => void;
};

type Props = IProps & InputHTMLAttributes<HTMLInputElement>;

// ----------------------------------------------------------------------

export default function RHFAutocomplete({
  name,
  id,
  label,
  inputValue,
  selectedOption,
  isLoading,
  isError,
  options,
  setInputValue,
  setIsLoading,
  handleInputOnChangeExtra,
  handleSelectOptionChange,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState: { error } }) => (
        <Autocomplete
          // {...field} // Commented out since currently needing to perform extra function on input change
          id={id}
          label={label}
          errMsg={error?.message}
          inputValue={inputValue}
          selectedOption={selectedOption}
          isLoading={isLoading}
          isError={isError}
          options={options}
          setInputValue={setInputValue}
          setIsLoading={setIsLoading}
          handleInputOnChangeExtra={handleInputOnChangeExtra}
          handleSelectOptionChange={handleSelectOptionChange}
          {...other}
        />
      )}
    />
  );
}

import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
// utils
import initMixpanel from './utils/mixpanel';
//
import router from './routes';

// ----------------------------------------------------------------------

export default function App() {
  //
  // Initialize Mixpanel
  useEffect(() => {
    initMixpanel();
  }, []);

  return <RouterProvider router={router} />;
}

import { createApi } from '@reduxjs/toolkit/query/react';
// @types
import { PaginatedHistoryResponseDto } from '@joonasvanhatapio/wp-cloud-backend-types';
//
import { axiosBaseQuery } from './axiosBaseQuery';

// ----------------------------------------------------------------------
// Change history page

export const historyApi = createApi({
  reducerPath: 'historyApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/v5/history' }),
  tagTypes: ['History'],
  endpoints: (builder) => ({
    getHistoryRecords: builder.query<
      PaginatedHistoryResponseDto,
      {
        actor: string;
        identifier: string;
        sortType: string;
        sortField: string;
        rowsPerPage: number;
        page: number;
      }
    >({
      query: ({ actor, identifier, sortType, sortField, rowsPerPage, page }) => {
        const searchParams = new URLSearchParams();
        searchParams.append('limit', rowsPerPage.toString());
        searchParams.append('page', page.toString());
        searchParams.append('sortOrder', sortType.toUpperCase());
        searchParams.append('sortBy', sortField);
        searchParams.append('actor', actor);
        searchParams.append('identifier', identifier);

        const searchParamsString = searchParams.toString();

        return {
          url: `/?${searchParamsString}`,
          method: 'get',
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'History' as const, id: id })),
              { type: 'History', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'History', id: 'PARTIAL-LIST' }],
    }),
  }),
});

export const { useGetHistoryRecordsQuery } = historyApi;

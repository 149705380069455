import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
// form
import { useForm } from 'react-hook-form';
// @types
import { CoreUpdatesEnum, UpdateSettings } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// components
import { FormProvider, RHFRadioGroup } from 'src/components/gravity/hook-form';
import Button from 'src/components/gravity/Button';
import Alert from 'src/components/gravity/Alert';
//
import SettingsSection from '../SettingsSection';

// ----------------------------------------------------------------------

type FormValuesProps = {
  includedUpdates: CoreUpdatesEnum;
};

type Props = {
  currentSettings: UpdateSettings;
  setCurrentSettings: Dispatch<SetStateAction<UpdateSettings>>;
};

// ----------------------------------------------------------------------

export default function CoreUpdatesSection({ currentSettings, setCurrentSettings }: Props) {
  // HOOK
  const { translate } = useLocales();

  // STATE
  const [isLoading, setisLoading] = useState<boolean>(false);

  const [saveCoreUpdatesSuccess, setSaveCoreUpdatesSuccess] = useState<boolean | undefined>(
    undefined
  );

  // FORM
  const defaultValues = {
    includedUpdates: currentSettings.core,
  };

  const methods = useForm<FormValuesProps>({
    defaultValues,
  });

  const { setValue, handleSubmit } = methods;

  //EVENT FUNCTION
  const onSubmit = async (data: FormValuesProps) => {
    if (currentSettings.core === data.includedUpdates) return;

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'save-core-updates-settings',
    });

    setisLoading(true);
    setTimeout(() => {
      setisLoading(false);

      const isSuccess: boolean = true;

      if (isSuccess) {
        setCurrentSettings((prev) => ({
          ...prev,
          core: data.includedUpdates,
        }));
      }

      setSaveCoreUpdatesSuccess(isSuccess);
    }, 2000);
  };

  //
  useEffect(() => {
    setValue('includedUpdates', currentSettings.core);
  }, [currentSettings, setValue]);

  return (
    <SettingsSection
      title={translate(
        'dashboard.sites.details.mwp.updates.updateSettings.coreUpdatesSection.title'
      )}
      description={translate(
        'dashboard.sites.details.mwp.updates.updateSettings.coreUpdatesSection.description'
      )}
      alert={
        saveCoreUpdatesSuccess !== undefined ? (
          <Alert
            type={saveCoreUpdatesSuccess ? 'success' : 'alert'}
            text={translate(
              `dashboard.sites.details.mwp.updates.updateSettings.coreUpdatesSection.alert.${
                saveCoreUpdatesSuccess ? 'success' : 'error'
              }`
            )}
            handleClose={() => setSaveCoreUpdatesSuccess(undefined)}
          />
        ) : undefined
      }
    >
      <div className="gv-w-full">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div className="gv-content-container gv-flex-column-md gv-p-lg">
            <div className="gv-flex-column-md">
              <RHFRadioGroup
                name="includedUpdates"
                options={Object.values(CoreUpdatesEnum).map((val) => ({
                  value: val,
                  label: translate(
                    `dashboard.sites.details.mwp.updates.updateSettings.coreUpdatesSection.form.options.${
                      val === CoreUpdatesEnum.ALL ? 'allUpdates' : 'minorUpdates'
                    }.title`
                  ),
                  description: translate(
                    `dashboard.sites.details.mwp.updates.updateSettings.coreUpdatesSection.form.options.${
                      val === CoreUpdatesEnum.ALL ? 'allUpdates' : 'minorUpdates'
                    }.description`
                  ),
                }))}
                condensed
                radioSpace="md"
              />
            </div>

            <div>
              <Button
                type="submit"
                text={translate('dashboard.general.action.save')}
                disabled={isLoading}
              />
            </div>
          </div>
        </FormProvider>
      </div>
    </SettingsSection>
  );
}

import { createApi } from '@reduxjs/toolkit/query/react';
// @types
import { SitePlanEnum, SiteBillingEnum, InstallationProvider } from 'src/@types/site';
import {
  ListAllSitesResponseDTO,
  ListSiteResponseDTO,
  CreateSiteDTO,
  CreateSiteResponseDTO,
  GetPasswordsResponseDTO,
  ResetSSHPasswordResponseDTO,
  WordPressLoginResponseDTO,
  ExecuteSSHResponse,
} from '@wp-one/sites-microservice-types';
// utils
import { convertToUnicode, convertToIDN, convertOldSiteOrderBy } from 'src/utils/convert';
//
import { axiosBaseQuery } from './axiosBaseQuery';

// ----------------------------------------------------------------------

export const siteApi = createApi({
  reducerPath: 'siteApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/v5/sites' }),
  tagTypes: ['Sites', 'Passwords'],
  endpoints: (builder) => ({
    // GLOBAL SEARCH SITES
    searchSites: builder.query<ListAllSitesResponseDTO, string>({
      query: (search) => {
        const searchParams = new URLSearchParams();

        searchParams.append('search', convertToIDN(search));

        const searchParamsString = searchParams.toString();

        return {
          url: `/list?limit=10&page=0&sortBy=hostname&sortOrder=asc&${searchParamsString}`,
          method: 'get',
        };
      },
      transformResponse: (response: ListAllSitesResponseDTO) => ({
        ...response,
        sites: response.sites.map((site) => ({
          ...site,
          provider:
            site.hostname.startsWith('frontend-mwp-testing-site') ||
            site.provider === InstallationProvider.ONE_HOP
              ? InstallationProvider.ONE_HOP
              : InstallationProvider.WP_ONE,
          hostname: convertToUnicode(site.hostname),
          additional_domains: site.additional_domains.map((additionalDomain) => ({
            ...additionalDomain,
            domain: convertToUnicode(additionalDomain.domain),
          })),
        })),
      }),
    }),
    // GET SITES
    getSites: builder.query<
      ListAllSitesResponseDTO,
      { rowsPerPage: number; page: number; orderBy: string; order: 'asc' | 'desc'; search: string }
    >({
      query: ({ rowsPerPage, page, orderBy, order, search }) => {
        const searchParams = new URLSearchParams();

        searchParams.append('limit', rowsPerPage.toString());
        searchParams.append('page', page.toString());
        searchParams.append('sortBy', convertOldSiteOrderBy(orderBy));
        searchParams.append('sortOrder', order);
        searchParams.append('search', convertToIDN(search));

        const searchParamsString = searchParams.toString();

        return {
          url: `/list?${searchParamsString}`,
          method: 'get',
        };
      },
      transformResponse: (response: ListAllSitesResponseDTO) => ({
        ...response,
        sites: response.sites.map((site) => ({
          ...site,
          provider:
            site.hostname.startsWith('frontend-mwp-testing-site') ||
            site.provider === InstallationProvider.ONE_HOP
              ? InstallationProvider.ONE_HOP
              : InstallationProvider.WP_ONE,
          hostname: convertToUnicode(site.hostname),
          additional_domains: site.additional_domains.map((additionalDomain) => ({
            ...additionalDomain,
            domain: convertToUnicode(additionalDomain.domain),
          })),
        })),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.sites.map(({ site_id }) => ({ type: 'Sites' as const, id: site_id })),
              { type: 'Sites', id: 'PARTIAL-LIST' },
              { type: 'Sites', id: 'SITE-LIST' },
            ]
          : [
              { type: 'Sites', id: 'PARTIAL-LIST' },
              { type: 'Sites', id: 'SITE-LIST' },
            ],
    }),
    // GET SITE BY NAME
    getSite: builder.query<ListSiteResponseDTO, { cluster: string; namespace: string }>({
      query: ({ cluster, namespace }) => ({
        url: `/${cluster}/${namespace}`,
        method: 'get',
      }),
      transformResponse: (response: ListSiteResponseDTO) => ({
        ...response,
        provider:
          response.hostname.startsWith('frontend-mwp-testing') ||
          response.provider === InstallationProvider.ONE_HOP
            ? InstallationProvider.ONE_HOP
            : InstallationProvider.WP_ONE,
        hostname: convertToUnicode(response.hostname),
        additional_domains: response.additional_domains.map((additionalDomain) => ({
          ...additionalDomain,
          domain: convertToUnicode(additionalDomain.domain),
        })),
      }),
      providesTags: (result) =>
        result
          ? [
              { type: 'Sites', id: result.site_id },
              { type: 'Sites', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Sites', id: 'PARTIAL-LIST' }],
    }),
    // ADD SITE
    addSite: builder.mutation<CreateSiteResponseDTO, CreateSiteDTO>({
      query: ({ hostname, email, notificationEmail, plan, billing, diskSpace }) => ({
        url: `/create`,
        method: 'post',
        data: {
          hostname: convertToIDN(hostname),
          email: email,
          notificationEmail: notificationEmail,
          plan: plan,
          billing: billing,
          diskSpace: diskSpace,
        },
      }),
      transformResponse: (response: CreateSiteResponseDTO) => ({
        ...response,
        hostname: convertToUnicode(response.hostname),
        additional_domains: response.additional_domains.map((additionalDomain) => ({
          ...additionalDomain,
          domain: convertToUnicode(additionalDomain.domain),
        })),
      }),
      invalidatesTags: (result) => (result ? [{ type: 'Sites', id: 'PARTIAL-LIST' }] : []),
    }),
    // ACTIVATE SITE
    activateSite: builder.mutation<boolean, { cluster: string; namespace: string }>({
      query: ({ cluster, namespace }) => ({
        url: `/${cluster}/${namespace}/activate`,
        method: 'post',
      }),
      invalidatesTags: (result) => (result ? [{ type: 'Sites', id: 'PARTIAL-LIST' }] : []),
    }),
    // DELETE SITE
    deleteSite: builder.mutation<
      boolean,
      { cluster: string; namespace: string; immediate: boolean }
    >({
      query: ({ cluster, namespace, immediate }) => ({
        url: `/${cluster}/${namespace}`,
        method: 'delete',
        data: {
          immediate,
        },
      }),
      invalidatesTags: (result) => (result ? [{ type: 'Sites', id: 'SITE-LIST' }] : []),
    }),
    // RESET SITE (not available on Swagger)
    resetSite: builder.mutation<
      // If response code !== 0, then the reset failed
      ExecuteSSHResponse,
      { namespace: string; ownerEmail: string | undefined; cluster: string }
    >({
      query: ({ cluster, namespace, ownerEmail }) => ({
        url: `/${cluster}/${namespace}/ssh/exec`,
        method: 'post',
        data: {
          command: ownerEmail
            ? `/usr/local/bin/wp-reset --email ${ownerEmail} --force`
            : '/usr/local/bin/wp-reset --force',
        },
      }),
    }),
    // UPGRADE STORAGE
    upgradeStorage: builder.mutation<
      boolean,
      {
        cluster: string;
        namespace: string;
        size: number;
      }
    >({
      query: ({ cluster, namespace, size }) => ({
        url: `/${cluster}/${namespace}/storage`,
        method: 'patch',
        data: {
          size: size,
        },
      }),
      invalidatesTags: (result) => (result ? [{ type: 'Sites', id: 'PARTIAL-LIST' }] : []),
    }),
    // GET PASSWORDS
    getPasswords: builder.query<GetPasswordsResponseDTO, { cluster: string; namespace: string }>({
      query: ({ cluster, namespace }) => ({
        url: `/${cluster}/${namespace}/passwords`,
        method: 'get',
      }),
      providesTags: [{ type: 'Passwords', id: 'PASSWORDS' }],
    }),
    //GENERATE NEW SSH/SFTP PASSWORD
    generateSSHPassword: builder.mutation<
      ResetSSHPasswordResponseDTO,
      { cluster: string; namespace: string }
    >({
      query: ({ cluster, namespace }) => ({
        url: `/${cluster}/${namespace}/ssh/reset`,
        method: 'post',
      }),
      invalidatesTags: (result) => (result ? [{ type: 'Passwords', id: 'PASSWORDS' }] : []),
    }),
    // ADD NEW SITE DOMAIN
    addSiteDomain: builder.mutation<
      boolean,
      {
        cluster: string;
        namespace: string;
        domainName: string;
      }
    >({
      query: ({ cluster, namespace, domainName }) => ({
        url: `/${cluster}/${namespace}/additional-domain`,
        method: 'post',
        data: {
          domain: convertToIDN(domainName),
        },
      }),
      invalidatesTags: (result) => (result ? [{ type: 'Sites', id: 'PARTIAL-LIST' }] : []),
    }),
    // DELETE SITE DOMAIN
    deleteSiteDomain: builder.mutation<
      boolean,
      { cluster: string; namespace: string; domainName: string }
    >({
      query: ({ cluster, namespace, domainName }) => ({
        url: `/${cluster}/${namespace}/additional-domain`,
        method: 'delete',
        data: {
          domain: convertToIDN(domainName),
        },
      }),
      invalidatesTags: (result) => (result ? [{ type: 'Sites', id: 'PARTIAL-LIST' }] : []),
    }),
    // WP ADMIN LOGIN
    requestWpAdmin: builder.query<
      WordPressLoginResponseDTO,
      { cluster: string; namespace: string; customerEmail: string | undefined }
    >({
      query: ({ cluster, namespace, customerEmail }) => {
        let url = `/${cluster}/${namespace}/login`;

        if (customerEmail) {
          const searchParams = new URLSearchParams();

          searchParams.append('auto_login_email', customerEmail);

          url += `?${searchParams.toString()}`;
        }

        return {
          url,
          method: 'get',
        };
      },
    }),
    // UPDATE PHP VERSION
    updatePHPVersion: builder.mutation<
      boolean,
      {
        cluster: string;
        namespace: string;
        phpVersion: number;
      }
    >({
      query: ({ cluster, namespace, phpVersion }) => ({
        url: `/${cluster}/${namespace}/php`,
        method: 'patch',
        data: {
          php_version: phpVersion,
        },
      }),
      invalidatesTags: (result) => (result ? [{ type: 'Sites', id: 'PARTIAL-LIST' }] : []),
    }),
    // UPDATE SITE PLAN
    updateSitePlan: builder.mutation<
      boolean,
      {
        cluster: string;
        namespace: string;
        data: {
          plan: SitePlanEnum;
          billing: SiteBillingEnum;
          skipCrm: boolean;
        };
      }
    >({
      query: ({ cluster, namespace, data }) => ({
        url: `/${cluster}/${namespace}/plan`,
        method: 'patch',
        data,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'Sites', id: 'PARTIAL-LIST' }] : []),
    }),
    // WP ADMIN LOGIN
    getWebspaceToken: builder.query<
      { webspace_api_token: string | null },
      { cluster: string; namespace: string }
    >({
      query: ({ cluster, namespace }) => ({
        url: `/${cluster}/${namespace}/token`,
        method: 'get',
      }),
    }),
    // GET CURRENT SITE LOG (not available on Swagger and Support only)
    getSiteLog: builder.query<
      // if response code === 0 then the get succeeded
      ExecuteSSHResponse,
      { siteId: string; logOption: string }
    >({
      query: ({ siteId, logOption }) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: {
          command:
            logOption === 'debug'
              ? `tail -n500 /data/wordpress/wp-content/debug.log`
              : `tail -n500 /data/logs/${logOption}.log`,
        },
      }),
    }),
    // GET CURRENT SITE DEBUG STATUS (not available on Swagger and Support only)
    getSiteDebugStatus: builder.query<
      // if response code === 0 AND stdout === true/"true" then status: on, else then status: off
      ExecuteSSHResponse,
      string
    >({
      query: (siteId) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: { command: 'wp config get WP_DEBUG --format=json --path=/data/wordpress' },
      }),
    }),
    // ACTIVATE SITE DEBUG (not available on Swagger and Support only)
    activateSiteDebugStatus: builder.mutation<ExecuteSSHResponse, string>({
      // If code === 0 then the activate succeeded
      query: (siteId) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: { command: 'wp config set WP_DEBUG true --raw --path=/data/wordpress' },
      }),
    }),
    // ACTIVATE SITE DEBUG LOG (not available on Swagger and Support only)
    activateSiteDebugLog: builder.mutation<ExecuteSSHResponse, string>({
      // If code === 0 then the activate succeeded
      query: (siteId) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: {
          command: 'wp config set WP_DEBUG_LOG true --raw --path=/data/wordpress',
        },
      }),
    }),
    // DEACTIVATE SITE DEBUG (not available on Swagger and Support only)
    deactivateSiteDebugStatus: builder.mutation<ExecuteSSHResponse, string>({
      // If code === 0 then the deactivate succeeded
      query: (siteId) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: { command: 'wp config delete WP_DEBUG --path=/data/wordpress' },
      }),
    }),
    // DEACTIVATE SITE DEBUG LOG (not available on Swagger and Support only)
    deactivateSiteDebugLog: builder.mutation<ExecuteSSHResponse, string>({
      // If code === 0 then the deactivate succeeded
      query: (siteId) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: {
          command: 'wp config delete WP_DEBUG_LOG --path=/data/wordpress',
        },
      }),
    }),
    // GET CURRENT SITE CACHE STATUS (not available on Swagger and Support only)
    getSiteCacheStatus: builder.query<
      ExecuteSSHResponse,
      string
      // if response code === 0 then status: off, else then status: on
    >({
      query: (siteId) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: { command: 'stat /data/nginx/wp-cache.conf' },
      }),
    }),
    // ENABLE SITE CACHE (not available on Swagger and Support only)
    enableSiteCache: builder.mutation<ExecuteSSHResponse, string>({
      // If code === 0 then the enable cache succceeded
      query: (siteId) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: { command: 'rm /data/nginx/wp-cache.conf' },
      }),
    }),
    // DISABLE SITE CACHE (not available on Swagger and Support only)
    disableSiteCache: builder.mutation<ExecuteSSHResponse, string>({
      // If code === 0 then the disable cache succceeded
      query: (siteId) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: { command: "echo 'set $disable_cache 1;' > /data/nginx/wp-cache.conf" },
      }),
    }),
    // FLUSH SITE CACHE (not available on Swagger and Support only)
    flushSiteCache: builder.mutation<ExecuteSSHResponse, string>({
      // If code === 0 then the flush cache succeeded
      query: (siteId) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: { command: 'wp-cache-flush' },
      }),
    }),
    // GET CURRENT WP MEMORY LIMIT (not available on Swagger and Support only)
    getSiteWPMemoryLimit: builder.query<
      // if response code === 0 then the get succeeded
      ExecuteSSHResponse,
      string
    >({
      query: (siteId) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: { command: 'wp config get WP_MEMORY_LIMIT --path=/data/wordpress' },
      }),
    }),
    // EDIT WP MEMORY LIMIT (not available on Swagger and Support only)
    editSiteWPMemoryLimit: builder.mutation<
      ExecuteSSHResponse,
      { siteId: string; newLimit: string }
    >({
      // If response code === 0 then the edit succeeded
      query: ({ siteId, newLimit }) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: { command: `wp config set WP_MEMORY_LIMIT "${newLimit}M" --path=/data/wordpress` },
      }),
    }),
    // REINSTALL SITE WP CORE (not available on Swagger and Support only)
    reinstallSiteWPCore: builder.mutation<ExecuteSSHResponse, string>({
      // If code === 0 then the reinstall succeeded
      query: (siteId) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: { command: 'wp core download --force --skip-content --path=/data/wordpress' },
      }),
    }),
    // GET CURRENT SITE SMTP BLOCKED STATUS (not available on Swagger and Support only)
    getSiteSMTPBlockedStatus: builder.query<
      ExecuteSSHResponse,
      string
      // if response code === 0 then status: on, else then status: off
    >({
      query: (siteId) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: { command: 'stat /data/.msmtp.blocked' },
      }),
    }),
    // ENABLE SITE SMTP BLOCK (not available on Swagger and Support only)
    enableSiteSMTPBlock: builder.mutation<ExecuteSSHResponse, string>({
      // If code === 0 then the enable succeeded
      query: (siteId) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: { command: 'touch /data/.msmtp.blocked' },
      }),
    }),
    // DISABLE SITE SMTP BLOCK (not available on Swagger and Support only)
    disableSiteSMTPBlock: builder.mutation<ExecuteSSHResponse, string>({
      // If code === 0 then the disable succeeded
      query: (siteId) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: { command: 'rm /data/.msmtp.blocked' },
      }),
    }),
    // GET CURRENT SITE REDIS PLUGIN STATUS (not available on Swagger and Support only)
    getSiteRedisPluginStatus: builder.query<
      ExecuteSSHResponse,
      string
      // if response code === 0 then status: on, else then status: off
    >({
      query: (siteId) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: { command: 'wp redis status --path=/data/wordpress' },
      }),
    }),
    // GET CURRENT SITE REDIS PLUGIN AVAILABILITY (not available on Swagger and Support only)
    getSiteRedisPluginAvailability: builder.query<
      ExecuteSSHResponse,
      string
      // if response code === 1 then the plugin is available, the toggle can be enabled
    >({
      query: (siteId) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: { command: 'ping redis-svc -w1' },
      }),
    }),
    // ENABLE SITE REDIS (Support only)
    enableSiteRedis: builder.mutation<boolean, string>({
      // If true then the enable succeeded
      query: (siteId) => ({
        url: `/${siteId}/redis`,
        method: 'post',
        data: {
          enabled: true,
        },
      }),
    }),
    activateSiteRedisCache: builder.mutation<ExecuteSSHResponse, string>({
      // If code === 0 then the activate succeeded
      query: (siteId) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: { command: 'wp plugin install --activate redis-cache --path=/data/wordpress' },
      }),
    }),
    enableSiteRedisSSH: builder.mutation<ExecuteSSHResponse, string>({
      // If code === 0 then the enable succeeded
      query: (siteId) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: { command: 'wp redis enable --path=/data/wordpress' },
      }),
    }),
    // DISABLE SITE REDIS (Support only)
    disableSiteRedis: builder.mutation<boolean, string>({
      // If true then the disable succeeded
      query: (siteId) => ({
        url: `/${siteId}/redis`,
        method: 'post',
        data: {
          enabled: false,
        },
      }),
    }),
    deactivateSiteRedisCache: builder.mutation<ExecuteSSHResponse, string>({
      // If code === 0 then the deactivate succeeded
      query: (siteId) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: { command: 'wp plugin delete redis-cache --path=/data/wordpress' },
      }),
    }),
    disableSiteRedisSSH: builder.mutation<ExecuteSSHResponse, string>({
      // Ignore the code check for this SSH command
      query: (siteId) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: { command: 'rm /data/wordpress/wp-content/object-cache.php' },
      }),
    }),
    // RESTART SERVER (Support only)
    restartServer: builder.mutation<boolean, string>({
      // If true then the restart succeeded
      query: (siteId) => ({
        url: `/${siteId}/restart`,
        method: 'post',
      }),
    }),
    // CHANGE PRIMARY HOSTNAME (Support and SA only)
    setWpHome: builder.mutation<ExecuteSSHResponse, { siteId: string; siteNewHostname: string }>({
      query: ({ siteId, siteNewHostname }) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: {
          command: `wp config set WP_HOME 'https://${siteNewHostname}' --path=/data/wordpress --skip-plugins --skip-themes`,
        },
      }),
    }),
    setWpSiteUrl: builder.mutation<ExecuteSSHResponse, { siteId: string; siteNewHostname: string }>(
      {
        query: ({ siteId, siteNewHostname }) => ({
          url: `/${siteId}/ssh/exec`,
          method: 'post',
          data: {
            command: `wp config set WP_SITEURL 'https://${siteNewHostname}' --path=/data/wordpress --skip-plugins --skip-themes`,
          },
        }),
      }
    ),
    searchReplace: builder.mutation<
      ExecuteSSHResponse,
      { siteId: string; siteNewHostname: string; siteOldHostname: string }
    >({
      query: ({ siteId, siteNewHostname, siteOldHostname }) => ({
        url: `/${siteId}/ssh/exec`,
        method: 'post',
        data: {
          command: `wp search-replace “//${siteOldHostname}” “//${siteNewHostname}” --all-tables --path=/data/wordpress --skip-plugins --skip-themes`,
        },
      }),
    }),
  }),
});

export const {
  useLazySearchSitesQuery,
  useGetSitesQuery,
  useLazyGetSiteQuery,
  useGetSiteQuery,
  useAddSiteMutation,
  useActivateSiteMutation,
  useDeleteSiteMutation,
  useResetSiteMutation,
  useUpgradeStorageMutation,
  useGenerateSSHPasswordMutation,
  useLazyGetPasswordsQuery,
  useAddSiteDomainMutation,
  useDeleteSiteDomainMutation,
  useLazyRequestWpAdminQuery,
  useUpdatePHPVersionMutation,
  useUpdateSitePlanMutation,
  useGetWebspaceTokenQuery,
  // Site details - Support tab
  useLazyGetSiteLogQuery,
  useLazyGetSiteDebugStatusQuery,
  useActivateSiteDebugStatusMutation,
  useActivateSiteDebugLogMutation,
  useDeactivateSiteDebugStatusMutation,
  useDeactivateSiteDebugLogMutation,
  useLazyGetSiteCacheStatusQuery,
  useEnableSiteCacheMutation,
  useDisableSiteCacheMutation,
  useFlushSiteCacheMutation,
  useLazyGetSiteWPMemoryLimitQuery,
  useEditSiteWPMemoryLimitMutation,
  useReinstallSiteWPCoreMutation,
  useLazyGetSiteSMTPBlockedStatusQuery,
  useEnableSiteSMTPBlockMutation,
  useDisableSiteSMTPBlockMutation,
  useLazyGetSiteRedisPluginAvailabilityQuery,
  useLazyGetSiteRedisPluginStatusQuery,
  useEnableSiteRedisMutation,
  useActivateSiteRedisCacheMutation,
  useEnableSiteRedisSSHMutation,
  useDisableSiteRedisMutation,
  useDeactivateSiteRedisCacheMutation,
  useDisableSiteRedisSSHMutation,
  useRestartServerMutation,
  // Site details - Domains tab
  useSetWpHomeMutation,
  useSetWpSiteUrlMutation,
  useSearchReplaceMutation,
} = siteApi;

import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import ReactGA from 'react-ga4';
// @types
import { UpdateSettings } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// utils
import { displayToast } from 'src/utils/handleToast';
// components
import { Toggle } from 'src/components/gravity/form';
import Alert from 'src/components/gravity/Alert';
import Indicator from 'src/components/gravity/Indicator';
//
import SettingsSection from '../SettingsSection';

// ----------------------------------------------------------------------

type Props = {
  currentSettings: UpdateSettings;
  setCurrentSettings: Dispatch<SetStateAction<UpdateSettings>>;
  onOpenDeactivateAutoUpdatesDialog: VoidFunction;
};

// ----------------------------------------------------------------------

export default function AutomaticUpdatesSection({
  currentSettings,
  setCurrentSettings,
  onOpenDeactivateAutoUpdatesDialog,
}: Props) {
  const { name, cluster, namespace } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  // STATE
  const [isLoading, setisLoading] = useState<boolean>(false);

  // VAR
  const numberOfPluginsThemes: number = currentSettings.autoUpdate.pluginThemeUpdates.length;
  const numberOfAutoUpdatePluginsThemes: number =
    currentSettings.autoUpdate.pluginThemeUpdates.filter(
      (update) => update.included === true
    ).length;

  // EVENT FUNCTION
  async function handleToggleAutoUpdates(value: boolean) {
    if (!value) {
      onOpenDeactivateAutoUpdatesDialog();
    } else {
      ReactGA.event({
        category: 'button',
        action: 'click',
        label: 'toggle-auto-update',
      });

      setisLoading(true);

      try {
        await new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve('Fetch successful');
            // reject('Fetch failed');
          }, 3000);
        });

        const isSuccess: boolean = false;

        if (isSuccess) {
          displayToast(
            translate(
              'dashboard.sites.details.mwp.updates.updateSettings.autoUpdatesSection.toast.success'
            )
          );
          setCurrentSettings((prev) => ({
            ...prev,
            autoUpdate: {
              ...prev.autoUpdate,
              activate: true,
            },
          }));
        } else {
          displayToast(
            translate(
              'dashboard.sites.details.mwp.updates.updateSettings.autoUpdatesSection.toast.error'
            ),
            {
              variant: 'alert',
            }
          );
        }
      } finally {
        setisLoading(false);
      }
    }
  }

  return (
    <SettingsSection
      title={
        <div
          className="gv-flex-row-sm gv-items-center"
          style={{
            width: '100%',
            justifyContent: isMobile ? 'space-between' : 'flex-start',
          }}
        >
          <p className="gv-text-lg gv-text-bold">
            {translate(
              'dashboard.sites.details.mwp.updates.updateSettings.autoUpdatesSection.title'
            )}
          </p>
          <Toggle
            labelId="activate"
            label={translate(
              'dashboard.sites.details.mwp.updates.updateSettings.autoUpdatesSection.actionLabel'
            )}
            condensed
            disabled={isLoading}
            checked={currentSettings.autoUpdate.activate}
            onChange={(e) => handleToggleAutoUpdates(e.currentTarget.checked)}
          />
        </div>
      }
      description={translate(
        'dashboard.sites.details.mwp.updates.updateSettings.autoUpdatesSection.description'
      )}
      alert={
        !currentSettings.autoUpdate.activate ? (
          <Alert type="warning" text={translate('dashboard.sites.details.mwp.updates.alert')} />
        ) : undefined
      }
    >
      {currentSettings.autoUpdate.activate && (
        <div className="gv-grid gv-gap-lg gv-grid-cols-2 gv-w-full">
          <AutoUpdatesOption
            title="dashboard.sites.details.mwp.updates.updateSettings.autoUpdatesSection.options.visualTesting.title"
            description="dashboard.sites.details.mwp.updates.updateSettings.autoUpdatesSection.options.visualTesting.description"
            iconName="preview"
            route={`/sites/updates/${cluster}/${namespace}/${name}/settings/visual-testing`}
            indicator={currentSettings.autoUpdate.visualTesting.activate}
          />

          <AutoUpdatesOption
            title="dashboard.sites.details.mwp.updates.updateSettings.autoUpdatesSection.options.updateSchedule.title"
            description={
              !currentSettings.autoUpdate.customSchedule.activate
                ? 'dashboard.sites.details.mwp.updates.updateSettings.autoUpdatesSection.options.updateSchedule.description.default'
                : `dashboard.sites.details.mwp.updates.updateSettings.autoUpdatesSection.options.updateSchedule.description.custom.${currentSettings.autoUpdate.customSchedule.settings.frequency}`
            }
            iconName="calendar_month"
            route={`/sites/updates/${cluster}/${namespace}/${name}/settings/schedule`}
          />

          <AutoUpdatesOption
            title="dashboard.sites.details.mwp.updates.updateSettings.autoUpdatesSection.options.pluginAndThemeUpdates.title"
            description={`dashboard.sites.details.mwp.updates.updateSettings.autoUpdatesSection.options.pluginAndThemeUpdates.description.${
              numberOfPluginsThemes === 0
                ? 'none'
                : numberOfAutoUpdatePluginsThemes === numberOfPluginsThemes
                ? 'allIncluded'
                : numberOfAutoUpdatePluginsThemes === 0
                ? 'noneIncluded'
                : 'partialIncluded'
            }`}
            iconName="settings"
            route={`/sites/updates/${cluster}/${namespace}/${name}/settings/plugin-and-theme-updates`}
          />
        </div>
      )}
    </SettingsSection>
  );
}

// ----------------------------------------------------------------------

type AutoUpdatesOptionProp = {
  title: string;
  description: string;
  iconName: string;
  route: string;
  indicator?: boolean; // Only for Visual update testing: true - Active, false - Inactive
};

// ----------------------------------------------------------------------

function AutoUpdatesOption({
  title,
  description,
  iconName,
  route,
  indicator,
}: AutoUpdatesOptionProp) {
  const navigate = useNavigate();

  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  return (
    <div
      className={`gv-content-container gv-px-lg gv-py-md gv-flex-row-md gv-items-center gv-span-${
        isMobile ? 2 : 1
      }`}
      onClick={() => navigate(route)}
      style={{
        cursor: 'pointer',
      }}
    >
      <gv-icon src={`/src/icons/${iconName}.svg`} />
      <div className="gv-flex-1">
        {indicator !== undefined && (
          <Indicator
            type={indicator ? 'success' : 'warning'}
            text={translate(
              `dashboard.sites.details.mwp.updates.updateSettings.autoUpdatesSection.options.visualTesting.status.${
                indicator ? 'active' : 'inactive'
              }`
            )}
            extraClass="gv-caption-lg gv-text-bold"
            textStyles={{
              color: `var(--color-signal-${indicator ? 'success' : 'warning'})`,
            }}
          />
        )}
        <p className="gv-text-lg gv-text-bold">{translate(title)}</p>
        <p className="gv-caption-lg gv-text-on-alternative">{translate(description)}</p>
      </div>
      <gv-icon src="/src/icons/arrow_forward.svg" />
    </div>
  );
}

import { useState } from 'react';
// @types
import { PluginThemeAutoUpdate } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// mock
import { DUMMY_INCLUDED_AUTO_UPDATES_SETTINGS_DATA } from 'src/mock/sites';
// components
import Alert from 'src/components/gravity/Alert';
//
import SettingsSection from '../SettingsSection';
import IncludedAutoUpdatesTable from './IncludedAutoUpdatesTable';

// ----------------------------------------------------------------------

export default function CustomScheduleSection() {
  // HOOK
  const { translate } = useLocales();

  // STATE
  const [currentIncludedAutoUpdatesSettings, setCurrentIncludedAutoUpdatesSettings] = useState<
    PluginThemeAutoUpdate[]
  >(DUMMY_INCLUDED_AUTO_UPDATES_SETTINGS_DATA);

  const [saveIncludedAutoUpdatesSuccess, setSaveIncludedAutoUpdatesSuccess] = useState<
    boolean | undefined
  >(undefined);

  return (
    <SettingsSection
      title={translate(
        'dashboard.sites.details.mwp.updates.pluginAndThemeUpdates.includedAutoUpdatesSection.title'
      )}
      description={translate(
        'dashboard.sites.details.mwp.updates.pluginAndThemeUpdates.includedAutoUpdatesSection.description'
      )}
      alert={
        saveIncludedAutoUpdatesSuccess !== undefined ? (
          <Alert
            type={saveIncludedAutoUpdatesSuccess ? 'success' : 'alert'}
            text={translate(
              `dashboard.sites.details.mwp.updates.pluginAndThemeUpdates.includedAutoUpdatesSection.alert.${
                saveIncludedAutoUpdatesSuccess ? 'success' : 'error'
              }`
            )}
            handleClose={() => setSaveIncludedAutoUpdatesSuccess(undefined)}
          />
        ) : undefined
      }
    >
      <IncludedAutoUpdatesTable
        currentIncludedAutoUpdatesSettings={currentIncludedAutoUpdatesSettings}
        setCurrentIncludedAutoUpdatesSettings={setCurrentIncludedAutoUpdatesSettings}
        setSaveIncludedAutoUpdatesSuccess={setSaveIncludedAutoUpdatesSuccess}
      />
    </SettingsSection>
  );
}

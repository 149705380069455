import { ReactNode } from 'react';
// components
import { PageSection } from 'src/components/gravity/page';

// ----------------------------------------------------------------------

type Props = {
  title: string | ReactNode;
  description: string;
  children: ReactNode;
  alert?: ReactNode;
};

// ----------------------------------------------------------------------

export default function SettingsSection({ title, description, children, alert }: Props) {
  return (
    <div className="gv-flex-column-lg">
      {alert && alert}

      <PageSection title={title} description={description}>
        {children}
      </PageSection>
    </div>
  );
}

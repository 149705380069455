import { useEffect } from 'react';
import { Outlet } from 'react-router';
// redux
import { useDispatch } from 'src/redux/store';
import { setSidebarHighlightActivePage } from 'src/redux/features/layout';

// ----------------------------------------------------------------------

export default function SiteDetailsUpdateLayout() {
  const dispatch = useDispatch();

  //
  useEffect(() => {
    dispatch(setSidebarHighlightActivePage(false));

    return () => {
      dispatch(setSidebarHighlightActivePage(true));
    };
  }, [dispatch]);

  return <Outlet />;
}

import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
// redux
import { useDispatch } from 'src/redux/store';
import { addUpdatingSite, removeUpdatingSite } from 'src/redux/features/site';
// @types
import { TableHeader } from 'src/@types/layout';
import { UpdateManualInfoRowProps } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
import useGravityTable from 'src/hooks/useGravityTable';
// utils
import { displayToast } from 'src/utils/handleToast';
// mock
import { DUMMY_MANUAL_UPDATES_DATA } from 'src/mock/sites';
// components
import { TableHeadCustom, TablePagination } from 'src/components/gravity/table';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import Button from 'src/components/gravity/Button';
//
import UpdateManualTableRow from './UpdateManualTableRow';

// ----------------------------------------------------------------------

const HEADERS: TableHeader[] = [
  {
    id: 'name',
    label: 'dashboard.sites.details.mwp.updates.updateManual.table.header.name',
  },
  {
    id: 'type',
    label: 'dashboard.sites.details.mwp.updates.updateManual.table.header.type',
  },
  {
    id: 'version',
    label: 'dashboard.sites.details.mwp.updates.updateManual.table.header.version',
  },
];

// ----------------------------------------------------------------------

export default function UpdateManualTable() {
  const { cluster, namespace, name } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // HOOK
  const { translate } = useLocales();

  const {
    page,
    rowsPerPage,
    //
    onChangePage,
  } = useGravityTable({
    defaultRowsPerPage: 25,
  });

  // STATE
  const [tableData, setTableData] = useState<UpdateManualInfoRowProps[]>(
    DUMMY_MANUAL_UPDATES_DATA.map((update, index) => ({
      id: index,
      selected: true,
      details: update,
    }))
  );

  const [isStarting, setIsStarting] = useState<boolean>(false);

  // VAR
  const isNoData = !tableData.length;

  // EVENT FUNCTION
  function handleSelectRow(id: number, checked: boolean) {
    setTableData((prev) =>
      prev.map((row) => (row.id === id ? { ...row, selected: checked } : row))
    );
  }

  function handleSelectAllRows(checked: boolean) {
    setTableData((prev) => prev.map((row) => ({ ...row, selected: checked })));
  }

  async function handleStartUpdate() {
    if (!name) return;

    console.log(
      'Update data: ',
      tableData.filter((update) => update.selected).map((update) => update.details)
    );

    setIsStarting(true);
    try {
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve('Update successful');
          // reject('Update failed');
        }, 1000);
      });

      setIsStarting(false);
      dispatch(addUpdatingSite(name));
      navigate(`/sites/updates/${cluster}/${namespace}/${name}`);

      setTimeout(() => {
        displayToast(translate('dashboard.sites.details.mwp.updates.updateManual.toast.success'));
        dispatch(removeUpdatingSite(name));
      }, 10000);
    } catch (error) {
      displayToast(translate('dashboard.sites.details.mwp.updates.updateManual.toast.error'), {
        variant: 'alert',
      });
      setIsStarting(false);
    }
  }

  return (
    <div className="gv-flex-column-md">
      <div
        className="gv-data-table"
        style={{
          overflowX: 'auto',
        }}
      >
        <table className="gv-col-1-shrink">
          <TableHeadCustom
            headers={HEADERS}
            rowCount={tableData.length}
            numSelected={tableData.filter((row) => row.selected).length}
            onSelectAllRows={handleSelectAllRows}
          />
          <tbody>
            {tableData.slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage).map((row) => (
              <UpdateManualTableRow key={row.id} row={row} handleSelectRow={handleSelectRow} />
            ))}
          </tbody>
        </table>

        {isNoData && (
          <div className="gv-empty-state">
            <div className="gv-empty-state-content">
              <h3 className="gv-title">
                {translate('dashboard.sites.details.mwp.updates.updateManual.table.noData.title')}
              </h3>
              <p>
                {translate(
                  'dashboard.sites.details.mwp.updates.updateManual.table.noData.description'
                )}
              </p>
            </div>
          </div>
        )}

        <TablePagination
          totalRows={tableData.length}
          currentPage={page}
          rowsPerPage={rowsPerPage}
          onChangePage={onChangePage}
        />
      </div>

      <ButtonGroup>
        <Button
          uiType="cancel"
          text={translate('dashboard.general.action.back')}
          onClick={() => navigate(`/sites/updates/${cluster}/${namespace}/${name}`)}
        />
        {!isNoData && (
          <Button
            text={translate(
              `dashboard.sites.details.mwp.updates.updateManual.table.action.${
                isStarting ? 'updating' : 'start'
              }`
            )}
            onClick={handleStartUpdate}
            disabled={isStarting}
          />
        )}
      </ButtonGroup>
    </div>
  );
}

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import ReactGA from 'react-ga4';
// @types
import { PluginThemeAutoUpdate } from 'src/@types/site';
import { TableHeader } from 'src/@types/layout';
// hooks
import useLocales from 'src/hooks/useLocales';
import useGravityTable from 'src/hooks/useGravityTable';
// components
import { TableHeadCustom, TablePagination } from 'src/components/gravity/table';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import Button from 'src/components/gravity/Button';
//
import IncludedAutoUpdatesTableRow from './IncludedAutoUpdatesTableRow';

// ----------------------------------------------------------------------

const TABLE_HEAD: TableHeader[] = [
  {
    id: 'name',
    label:
      'dashboard.sites.details.mwp.updates.pluginAndThemeUpdates.includedAutoUpdatesSection.table.header.name',
    sort: true,
  },
  {
    id: 'type',
    label:
      'dashboard.sites.details.mwp.updates.pluginAndThemeUpdates.includedAutoUpdatesSection.table.header.type',
    sort: true,
  },
  {
    id: 'version',
    label:
      'dashboard.sites.details.mwp.updates.pluginAndThemeUpdates.includedAutoUpdatesSection.table.header.version',
    sort: true,
  },
  {
    id: 'status',
    label:
      'dashboard.sites.details.mwp.updates.pluginAndThemeUpdates.includedAutoUpdatesSection.table.header.status',
    sort: true,
  },
];

// ----------------------------------------------------------------------

type Props = {
  currentIncludedAutoUpdatesSettings: PluginThemeAutoUpdate[];
  setCurrentIncludedAutoUpdatesSettings: Dispatch<SetStateAction<PluginThemeAutoUpdate[]>>;
  setSaveIncludedAutoUpdatesSuccess: Dispatch<SetStateAction<undefined | boolean>>;
};

// ----------------------------------------------------------------------

export default function IncludedAutoUpdatesTable({
  currentIncludedAutoUpdatesSettings,
  setCurrentIncludedAutoUpdatesSettings,
  setSaveIncludedAutoUpdatesSuccess,
}: Props) {
  const { cluster, namespace, name } = useParams();

  const navigate = useNavigate();

  // HOOK
  const { translate } = useLocales();

  const { page, order, orderBy, rowsPerPage, setPage, onSort, onChangePage } = useGravityTable({
    defaultOrderBy: 'name',
    defaultOrder: 'asc',
    defaultRowsPerPage: 25,
  });

  // STATE
  const [tableData, setTableData] = useState<PluginThemeAutoUpdate[]>(
    currentIncludedAutoUpdatesSettings
  );

  const [isLoading, setisLoading] = useState<boolean>(false);

  // VAR
  const filteredData = [...tableData].sort(filterTableSort(orderBy, order));

  const isNoData = !filteredData.length;

  // EVENT FUNCTION
  function handleSelectAllRows(checked: boolean) {
    setTableData((prev) => prev.map((row) => ({ ...row, included: checked })));
  }

  function handleSelectRow(id: string, checked: boolean) {
    setTableData((prev) =>
      prev.map((row) => (`${row.type}${row.title}` === id ? { ...row, included: checked } : row))
    );
  }

  function handleSaveSettings() {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'save-custom-schedule-settings',
    });

    setisLoading(true);
    setTimeout(() => {
      setisLoading(false);

      const isSuccess: boolean = true;

      if (isSuccess) {
        setCurrentIncludedAutoUpdatesSettings(tableData);
      }

      setSaveIncludedAutoUpdatesSuccess(isSuccess);
    }, 2000);
  }

  // HELPER FUNCTION
  function filterTableSort(
    orderBy: string,
    order: 'asc' | 'desc'
  ): (a: PluginThemeAutoUpdate, b: PluginThemeAutoUpdate) => number {
    return (a: PluginThemeAutoUpdate, b: PluginThemeAutoUpdate) => {
      let aValue: string = '';
      let bValue: string = '';

      if (orderBy === 'name') {
        aValue = a.title;
        bValue = b.title;
      } else if (orderBy === 'type') {
        aValue = a.type;
        bValue = b.type;
      } else if (orderBy === 'version') {
        aValue = a.version;
        bValue = b.version;
      } else {
        aValue = a.included.toString();
        bValue = b.included.toString();
      }

      if (aValue === bValue) {
        return a.title.localeCompare(b.title);
      }

      return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    };
  }

  //
  // Prevent no data after deleting record
  useEffect(() => {
    const currentPageRows = filteredData.slice(
      page * rowsPerPage - rowsPerPage,
      page * rowsPerPage
    ).length;

    if (currentPageRows === 0 && page !== 1) {
      setPage(1);
    }
  }, [page, rowsPerPage, setPage, filteredData]);

  return (
    <div className="gv-flex-column-md gv-w-full">
      <div className="gv-data-table">
        <div className="data-table-scroll-handler" style={{ overflowX: 'auto' }}>
          <table className="gv-col-1-shrink">
            <TableHeadCustom
              headers={TABLE_HEAD}
              rowCount={filteredData.length}
              numSelected={filteredData.filter((row) => row.included).length}
              onSelectAllRows={handleSelectAllRows}
              order={order}
              orderBy={orderBy}
              onSort={onSort}
            />
            <tbody>
              {filteredData
                .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
                .map((row) => (
                  <IncludedAutoUpdatesTableRow
                    key={`${row.type}${row.title}`}
                    currentIncludedAutoUpdatesSettings={currentIncludedAutoUpdatesSettings}
                    row={row}
                    handleSelectRow={handleSelectRow}
                  />
                ))}
            </tbody>
          </table>
        </div>

        {isNoData && (
          <div className="gv-empty-state">
            <div className="gv-empty-state-content">
              {translate(
                'dashboard.sites.details.mwp.updates.pluginAndThemeUpdates.includedAutoUpdatesSection.table.noData'
              )}
            </div>
          </div>
        )}

        {!isNoData && (
          <TablePagination
            totalRows={filteredData.length || 0}
            currentPage={page}
            onChangePage={onChangePage}
            rowsPerPage={rowsPerPage}
          />
        )}
      </div>

      <ButtonGroup>
        <Button
          uiType="cancel"
          text={translate('wpone.general.action.back')}
          onClick={() => navigate(`/sites/updates/${cluster}/${namespace}/${name}/settings`)}
        />
        {!isNoData && (
          <Button
            text={translate('dashboard.general.action.save')}
            disabled={isLoading}
            onClick={handleSaveSettings}
          />
        )}
      </ButtonGroup>
    </div>
  );
}

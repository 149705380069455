import {
  Dispatch,
  InputHTMLAttributes,
  Ref,
  SetStateAction,
  forwardRef,
  useRef,
  useState,
} from 'react';
// hooks
import useLocales from 'src/hooks/useLocales';
import useClickOutsideEffect from 'src/hooks/useClickOutside';

// ----------------------------------------------------------------------

type IProps = {
  id: string;
  label: string;
  errMsg?: string;
  inputValue: string;
  selectedOption: string;
  isLoading: boolean;
  isError: boolean;
  options: string[];
  setInputValue: Dispatch<SetStateAction<string>>;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  handleInputOnChangeExtra?: (input: string) => void;
  handleSelectOptionChange: (input: string) => void;
};

type Props = IProps & InputHTMLAttributes<HTMLInputElement>;

// ----------------------------------------------------------------------

const Autocomplete = forwardRef(
  (
    {
      id,
      label,
      errMsg,
      inputValue,
      selectedOption,
      isLoading,
      isError,
      options,
      setInputValue,
      setIsLoading,
      handleInputOnChangeExtra,
      handleSelectOptionChange,
      ...other
    }: Props,
    ref: Ref<HTMLInputElement>
  ) => {
    const textFieldRef = useRef<HTMLDivElement>(null);
    const panelRef = useRef<HTMLDivElement>(null);

    // STATE
    const [open, setOpen] = useState<boolean>(false);

    // HOOK
    const { translate } = useLocales();

    // Handle losing focus only
    useClickOutsideEffect({
      outsideRefs: [textFieldRef, panelRef],
      handleClickingOutside: () => {
        setOpen(false);
        if (selectedOption !== inputValue) {
          setInputValue('');
          handleSelectOptionChange('');
        }
      },
      shouldListen: open,
    });

    return (
      <div style={{ position: 'relative' }}>
        <div className="gv-form-option gv-autocomplete">
          <label id={`${id}-label`} htmlFor={id} className="gv-label">
            {label}
          </label>
          <div ref={textFieldRef} className="gv-input gv-with-addon">
            <input
              ref={ref}
              id={id}
              type="text"
              aria-autocomplete="list"
              aria-expanded={open && !isLoading && !isError}
              role="combobox"
              value={inputValue}
              onChange={(e) => {
                setOpen(true);
                if (setIsLoading) {
                  setIsLoading(true);
                }
                setInputValue(e.target.value);
                if (handleInputOnChangeExtra) {
                  handleInputOnChangeExtra(e.target.value);
                }
              }}
              onFocus={(e) => {
                if (!open) {
                  setOpen(true);
                  if (setIsLoading) {
                    setIsLoading(true);
                  }
                  if (handleInputOnChangeExtra) {
                    handleInputOnChangeExtra(e.target.value);
                  }
                }
              }}
              aria-controls={open ? `${id}-listbox` : undefined}
              {...other}
            />
            <button
              type="button"
              className={`gv-addon${open ? ' gv-expanded' : ''}`}
              aria-label={open ? 'Open' : 'Close'}
              onClick={() => {
                if (open) {
                  setOpen(false);
                  if (selectedOption !== inputValue) {
                    setInputValue('');
                    handleSelectOptionChange('');
                  }
                } else {
                  setOpen(true);
                  if (setIsLoading) {
                    setIsLoading(true);
                  }
                  if (handleInputOnChangeExtra) {
                    handleInputOnChangeExtra(inputValue);
                  }
                }
              }}
            >
              <gv-icon src="/src/icons/expand_more.svg" aria-hidden="true" />
            </button>
          </div>
          {errMsg && <span className="gv-input-message gv-error">{errMsg}</span>}
        </div>

        {open &&
          (isLoading ? (
            <div
              ref={panelRef}
              className="gv-autocomplete-panel"
              role="presentation"
              style={{ transform: 'translate(0px, 73px)' }}
            >
              <div className="gv-state-info gv-state-loading">
                {translate('wpone.general.inputField.autocomplete.loading')}
              </div>
            </div>
          ) : isError || options.length === 0 ? (
            <div
              ref={panelRef}
              className="gv-autocomplete-panel"
              role="presentation"
              style={{ transform: 'translate(0px, 73px)' }}
            >
              <div className="gv-state-info gv-state-no-options">
                {translate('wpone.general.inputField.autocomplete.error')}
              </div>
            </div>
          ) : (
            <div
              ref={panelRef}
              className="gv-autocomplete-panel"
              role="presentation"
              style={{ transform: 'translate(0px, 73px)' }}
            >
              <ul
                id={`${id}-listbox`}
                className="gv-listbox"
                role="listbox"
                aria-labelledby={`${id}-label`}
              >
                {options.map((option, index) => (
                  <li
                    key={`${id}-option-${index}`}
                    tabIndex={-1}
                    id={`${id}-option-${index}`}
                    role="option"
                    aria-selected="false"
                    className="gv-option"
                    onClick={() => {
                      setOpen(false);
                      setInputValue(option);
                      handleSelectOptionChange(option);
                    }}
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          ))}
      </div>
    );
  }
);

export default Autocomplete;

import { useState, useEffect, useMemo, memo, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';
import ReactGA from 'react-ga4';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { useLazySearchSitesQuery } from 'src/redux/api/siteApi';
import { useLazySearchDomainsQuery } from 'src/redux/api/domainApi';
import { toggleScrollLock, setGlobalSearchHistory } from 'src/redux/features/layout';
// @types
import { GlobalSearchOptionProps, GlobalSearchGroupEnum } from 'src/@types/layout';
// hooks
import useLocales from 'src/hooks/useLocales';
import useClickOutsideEffect from 'src/hooks/useClickOutside';
// utils
import { convertToIDN } from 'src/utils/convert';

// ----------------------------------------------------------------------

function Searchbar() {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const searchbarRef = useRef<HTMLDivElement>(null);

  const searchbarPanelRef = useRef<HTMLDivElement>(null);

  const chipBarRef = useRef<HTMLDivElement>(null);

  const chipItemsRef = useRef<HTMLDivElement>(null);

  // STATE
  const [openSearchbar, setOpenSearchbar] = useState<boolean>(false);

  const [selectedChip, setSelectedChip] = useState<GlobalSearchGroupEnum>(
    GlobalSearchGroupEnum.ALL
  );

  const [showAllResults, setShowAllResults] = useState<boolean>(false);

  const [filterName, setFilterName] = useState<string>('');

  const [data, setData] = useState<GlobalSearchOptionProps[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [chipBarPosition, setChipBarPosition] = useState<number>(0);

  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);

  const [showRightArrow, setShowRightArrow] = useState<boolean>(true);

  // HOOK
  const { translate } = useLocales();

  useClickOutsideEffect({
    outsideRefs: [searchbarRef, searchbarPanelRef],
    handleClickingOutside: handleCloseSearchbar,
    shouldListen: openSearchbar,
  });

  // API
  const [searchSites, { data: sitesData, isFetching: sitesIsFetching }] = useLazySearchSitesQuery();
  const [searchDomains, { data: domainsData, isFetching: domainsIsFetching }] =
    useLazySearchDomainsQuery();

  // VAR
  const searchHistory = useSelector((state) => state.layout.globalSearchHistory);
  const filteredData =
    selectedChip === GlobalSearchGroupEnum.ALL
      ? data
      : data.filter((item) => item.group === selectedChip);

  // EVENT FUNCTION
  function handleOpenSearchbar() {
    setOpenSearchbar(true);
    dispatch(toggleScrollLock(true));
  }

  function handleCloseSearchbar() {
    setFilterName('');
    setData([]);
    setSelectedChip(GlobalSearchGroupEnum.ALL);
    setOpenSearchbar(false);
    dispatch(toggleScrollLock(false));
  }

  function handleClearHistory() {
    dispatch(setGlobalSearchHistory([]));
  }

  function handleSeeMore() {
    setShowAllResults(true);
  }

  function handleChipClick(chip: GlobalSearchGroupEnum) {
    setSelectedChip(chip);
  }

  function handleFilterName(newFilterName: string) {
    setIsLoading(true);
    getSearch(newFilterName.toLocaleLowerCase().trim());
    setFilterName(newFilterName);
    if (newFilterName.trim() === '') {
      setData([]);
      setIsLoading(false);
    }
  }

  function handleResultClick(result: GlobalSearchOptionProps) {
    // Always add new search to top of the history
    const updatedSearchHistory = searchHistory.filter((item) => item.id !== result.id);
    updatedSearchHistory.unshift(result);
    // Make sure history will not exceed 10 results
    if (updatedSearchHistory.length > 10) {
      updatedSearchHistory.pop();
    }
    dispatch(setGlobalSearchHistory(updatedSearchHistory));

    handleCloseSearchbar();

    if (result.group === GlobalSearchGroupEnum.SITES) {
      navigate(
        `/sites/overview/${result.cluster}/${result.namespace}/${result.fromSite || result.name}`
      );
    } else {
      navigate(`/dns/records/${result.name}`);
    }
  }

  function handleChipBarSlide(arrowDirection: 'left' | 'right') {
    const chipBarWidth = chipBarRef.current?.offsetWidth || 0;
    const chipItemsWidth = chipItemsRef.current?.scrollWidth || 0;

    // Chip bar position 0 means it's at the very left (initial position)
    // Negative values move it to the left, revealing more chip items to the right
    let newPosition: number;

    // For both direction, the position will be moved one chipBarWidth unit at a time
    if (arrowDirection === 'left') {
      // Moves position to the right
      // Ensure new position never exceeds leftmost boundary (0)
      newPosition = Math.min(chipBarPosition + chipBarWidth, 0);
    } else {
      // Moves position to the left
      // Ensure new position never exceeds rightmost boundary (-chipItemsWidth + chipBarWidth)
      newPosition = Math.max(chipBarPosition - chipBarWidth, -chipItemsWidth + chipBarWidth);
    }

    setChipBarPosition(newPosition);
    setShowLeftArrow(newPosition < 0);
    setShowRightArrow(newPosition > -chipItemsWidth + chipBarWidth);
  }

  // HELPER FUNCTION
  function highlightKeyword(text: string, keyword: string) {
    const trimmedText = text.trim();
    const trimmedKeyword = keyword.trim();
    const parts = trimmedText.split(new RegExp(`(${trimmedKeyword})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === trimmedKeyword.toLowerCase() ? (
        <span key={index} className="gv-highlight">
          {part}
        </span>
      ) : (
        part
      )
    );
  }

  const getSearch = useMemo(
    () =>
      debounce((searchInput) => {
        if (searchInput) {
          searchSites(searchInput);
          searchDomains(searchInput);
          ReactGA.event('search', {
            search_term: searchInput,
          });
        }
      }, 300),
    [searchSites, searchDomains]
  );

  //
  useEffect(() => {
    if (!sitesIsFetching && !domainsIsFetching) {
      setIsLoading(false);
      if (!sitesData || !domainsData) {
        setData([]);
      } else {
        let formattedSitesData: GlobalSearchOptionProps[] = [];
        sitesData.sites.forEach((site) => {
          // If site name includes the keyword, then add it to the result
          if (
            convertToIDN(site.hostname).includes(
              convertToIDN(filterName.toLocaleLowerCase().trim())
            )
          ) {
            formattedSitesData.push({
              id: `site-${site.hostname}`,
              group: GlobalSearchGroupEnum.SITES,
              name: site.hostname,
              cluster: site.cluster.name,
              namespace: site.namespace,
            });
          }
          // Then check every additional domain this site has, if it includes the keyword, add it to the result as well
          site.additional_domains.forEach((additionalDomain) => {
            if (
              convertToIDN(additionalDomain.domain).includes(
                convertToIDN(filterName.toLocaleLowerCase().trim())
              )
            ) {
              formattedSitesData.push({
                id: `additional-domain-${additionalDomain.domain}`,
                group: GlobalSearchGroupEnum.SITES,
                name: additionalDomain.domain,
                fromSite: site.hostname,
                cluster: site.cluster.name,
                namespace: site.namespace,
              });
            }
          });
        });
        // Get the all data from first 10 sites AND additional domain if any of those 10 sites have any AND all DNS domains
        const allData = formattedSitesData.concat(
          domainsData.zones.map((domain) => ({
            id: `domains-${domain.name}`,
            group: GlobalSearchGroupEnum.DOMAINS,
            name: domain.name,
          }))
        );
        // List the results alphabetically with site always display first when the name is the same
        allData.sort((a, b) => {
          if (a.name === b.name) {
            return a.group === GlobalSearchGroupEnum.SITES ? -1 : 1;
          }
          return a.name.localeCompare(b.name);
        });

        setData(allData);
      }
    } else {
      setIsLoading(true);
      setData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sitesIsFetching, domainsIsFetching]);

  useEffect(
    () => () => {
      handleCloseSearchbar(); // Cannot be inside deps array since it will force this to rerun everytime component is rerender
      getSearch.cancel();
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getSearch, pathname]
  );

  useEffect(() => {
    const chipBarWidth = chipBarRef.current?.offsetWidth || 0;
    const chipItemsWidth = chipItemsRef.current?.scrollWidth || 0;
    setShowRightArrow(chipItemsWidth > chipBarWidth);
  }, [data]);

  return (
    <div>
      <button
        aria-expanded={openSearchbar}
        aria-controls="search-overlay"
        onClick={handleOpenSearchbar}
        style={{ display: 'flex' }}
      >
        <gv-icon src="/src/icons/search.svg" />
      </button>

      {openSearchbar && (
        <div
          id="search-overlay"
          className="gv-search-advanced"
          role="dialog"
          aria-hidden={!openSearchbar}
        >
          <div className="gv-search-container">
            <div className="gv-search-content">
              <div className="gv-search-header gv-autocomplete" ref={searchbarRef}>
                <button
                  type="button"
                  className="gv-button gv-button-cancel"
                  onClick={handleCloseSearchbar}
                >
                  <gv-icon src="/src/icons/arrow_back.svg" />
                </button>

                <div className="gv-input gv-input-search gv-with-clear">
                  <input
                    autoComplete="off"
                    autoFocus
                    id="search-input"
                    type="search"
                    role="combobox"
                    aria-expanded="true"
                    aria-autocomplete="list"
                    aria-controls="search-panel"
                    placeholder={translate('wpone.globalSearch.placeholder')}
                    value={filterName}
                    onChange={(e) => {
                      handleFilterName(e.target.value);
                    }}
                  />
                  <button
                    className="gv-clear"
                    aria-label="Clear"
                    onClick={() => {
                      setFilterName('');
                      setData([]);
                      setSelectedChip(GlobalSearchGroupEnum.ALL);
                      document.getElementById('search-input')?.focus();
                    }}
                  >
                    <gv-icon src="/src/icons/close_small.svg" aria-hidden="true" />
                  </button>
                  <button className="gv-addon gv-expanded" aria-label="Search">
                    <gv-icon src="/src/icons/search.svg" />
                  </button>
                </div>
              </div>

              <div
                className="gv-autocomplete-panel"
                id="search-panel"
                role="region"
                ref={searchbarPanelRef}
              >
                {!isLoading && data.length > 0 && (
                  <div className="gv-panel-chips" role="group" aria-label="Filters">
                    <button
                      className="gv-slide-button gv-previous"
                      aria-label="Slide left"
                      onClick={() => handleChipBarSlide('left')}
                      hidden={!showLeftArrow}
                    >
                      <gv-icon src="src/icons/chevron_left.svg" />
                    </button>
                    <div className="gv-bar" ref={chipBarRef}>
                      <div
                        className="gv-items"
                        ref={chipItemsRef}
                        style={{ transform: `translateX(${chipBarPosition}px)` }}
                      >
                        {Object.values(GlobalSearchGroupEnum).map((chip) => (
                          <button
                            key={chip}
                            className={`gv-chip gv-chip-radio ${
                              selectedChip === chip ? 'gv-chip-active' : ''
                            }`}
                            onClick={() => handleChipClick(chip)}
                          >
                            {translate(`wpone.globalSearch.groupLabel.${chip}`)}
                          </button>
                        ))}
                      </div>
                    </div>
                    <button
                      className="gv-slide-button gv-next"
                      aria-label="Slide right"
                      onClick={() => handleChipBarSlide('right')}
                      hidden={!showRightArrow}
                    >
                      <gv-icon src="src/icons/chevron_right.svg" />
                    </button>
                  </div>
                )}

                {isLoading ? (
                  <div className="gv-state-info gv-state-loading" aria-live="polite">
                    <gv-loader src="/src/loaders/spinner.svg" />
                    <p className="gv-title">
                      {translate('wpone.globalSearch.optionLoading.title')}
                    </p>
                    <p>{translate('wpone.globalSearch.optionLoading.description')}</p>
                  </div>
                ) : filteredData.length === 0 && filterName.length > 0 ? (
                  <div className="gv-state-info gv-state-no-options" aria-live="polite">
                    <p className="gv-title">
                      {translate('wpone.globalSearch.optionNotFound.title', {
                        searchQuery: filterName,
                      })}
                    </p>
                    <p>{translate('wpone.globalSearch.optionNotFound.description')}</p>
                  </div>
                ) : filteredData.length > 0 ? (
                  <div className="gv-panel-results">
                    <div className="gv-panel-header">
                      <span className="gv-title">
                        {filteredData.length > 1
                          ? translate('wpone.globalSearch.resultsFound.plural', {
                              number: filteredData.length,
                            })
                          : translate('wpone.globalSearch.resultsFound.singular')}
                      </span>
                    </div>
                    <ul id="results-list" className="gv-listbox" role="listbox" aria-live="polite">
                      {(showAllResults ? filteredData : filteredData.slice(0, 10)).map(
                        (result, index) => (
                          <li
                            key={index}
                            tabIndex={-1}
                            id={`result-${index}`}
                            className="gv-option"
                            onClick={() => handleResultClick(result)}
                          >
                            <span className="gv-with-underline">
                              <span>{highlightKeyword(result.name, filterName.trim())}</span>

                              <span className="gv-underline">
                                {translate(
                                  `wpone.globalSearch.additonalDomainOption.${
                                    result.group === GlobalSearchGroupEnum.SITES ? 'site' : 'domain'
                                  }`
                                )}
                                {result.fromSite &&
                                  ` - ${translate(
                                    'wpone.globalSearch.additonalDomainOption.connectedTo',
                                    {
                                      site: result.fromSite,
                                    }
                                  )}`}
                              </span>
                            </span>
                          </li>
                        )
                      )}
                    </ul>
                    {!showAllResults && filteredData.length > 10 && (
                      <button className="gv-action" onClick={handleSeeMore}>
                        <span>{translate('wpone.globalSearch.resultsFound.seeMore')}</span>
                      </button>
                    )}
                  </div>
                ) : searchHistory.length === 0 ? (
                  <div className="gv-state-info gv-state-no-options" aria-live="polite">
                    <p className="gv-title">
                      {translate('wpone.globalSearch.searchHistory.noHistory.title')}
                    </p>
                    <p>{translate('wpone.globalSearch.searchHistory.noHistory.description')}</p>
                  </div>
                ) : (
                  <div className="gv-panel-results">
                    <div className="gv-panel-header">
                      <span className="gv-title">
                        {translate('wpone.globalSearch.searchHistory.history.title')}
                      </span>
                      <button className="gv-action" onClick={handleClearHistory}>
                        <span>{translate('wpone.globalSearch.searchHistory.history.action')}</span>
                      </button>
                    </div>
                    <ul id="history-list" className="gv-listbox" role="listbox" aria-live="polite">
                      {searchHistory.map((historyItem, index) => (
                        <li
                          key={index}
                          tabIndex={-1}
                          id={`history-${index}`}
                          className="gv-option"
                          onClick={() => handleResultClick(historyItem)}
                        >
                          <span className="gv-text-icon">
                            <gv-icon src="/src/icons/history.svg" />
                            <span>{historyItem.name}</span>
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(Searchbar);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// @types
import {
  FeatureReleaseDetails,
  GlobalSearchOptionProps,
  LayoutState,
  PageDetailsTabConfig,
} from 'src/@types/layout';
import { SiteBrandEnum } from 'src/@types/site';
// config
import { FOOTER } from 'src/config';

// ----------------------------------------------------------------------

const initialState: LayoutState = {
  showNewFeatureModal: false,
  lastReadFeatureVersion: 1.0,
  displayFeatureArr: [],
  //
  bannerHeight: 0,
  footerHeight: FOOTER.HEIGHT,
  sidebarHighlightActivePage: true,
  tabConfig: undefined,
  siteBrand: undefined,
  //
  showActionsInProgressBanner: false,
  //
  enableScrollLock: false,
  overflowPadding: 0,
  //
  globalSearchHistory: [],
};

const slice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    // SET MODAL STATE
    setShowModal(state, action: PayloadAction<boolean>) {
      state.showNewFeatureModal = action.payload;
    },
    // SET LAST READ FEATURE VERSION
    setLastReadFeatureVersion(state, action: PayloadAction<number>) {
      state.lastReadFeatureVersion = action.payload;
    },
    // SET DISPLAY FEATURE ARRAY
    setDisplayFeatureArr(state, action: PayloadAction<FeatureReleaseDetails[]>) {
      state.displayFeatureArr = action.payload;
    },
    // SET BANNER HEIGHT
    setBannerHeight(state, action: PayloadAction<number>) {
      state.bannerHeight = action.payload;
    },
    // SET FOOTER HEIGHT
    setFooterHeight(state, action: PayloadAction<number>) {
      state.footerHeight = action.payload;
    },
    // SET SIDEBAR HIGHLIGHT ACTIVE PAGE
    setSidebarHighlightActivePage(state, action: PayloadAction<boolean>) {
      state.sidebarHighlightActivePage = action.payload;
    },
    // SET CURRENT TAB CONFIG
    setTabConfig(state, action: PayloadAction<PageDetailsTabConfig | undefined>) {
      state.tabConfig = action.payload;
    },
    // SET SITE BRAND
    setSiteBrand(state, action: PayloadAction<SiteBrandEnum | undefined>) {
      state.siteBrand = action.payload;
    },
    // TOGGLE ACTIONS IN PROGRESS BANNER
    toggleActionsInProgressBanner(state, action: PayloadAction<boolean>) {
      state.showActionsInProgressBanner = action.payload;
      if (!action.payload) {
        state.bannerHeight = 0;
      }
    },
    // TOGGLE SCROLL LOCK
    toggleScrollLock(state, action: PayloadAction<boolean>) {
      state.enableScrollLock = action.payload;
      if (action.payload) {
        state.overflowPadding = window.innerWidth - document.documentElement.clientWidth;
        document.body.style.overflow = 'hidden';
      } else {
        state.overflowPadding = 0;
        document.body.style.overflow = 'auto';
      }
    },
    // SET SEARCH HISTORY
    setGlobalSearchHistory(state, action: PayloadAction<GlobalSearchOptionProps[]>) {
      state.globalSearchHistory = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setShowModal,
  setLastReadFeatureVersion,
  setDisplayFeatureArr,
  setBannerHeight,
  setFooterHeight,
  setSidebarHighlightActivePage,
  setTabConfig,
  setSiteBrand,
  setGlobalSearchHistory,
  toggleActionsInProgressBanner,
  toggleScrollLock,
} = slice.actions;

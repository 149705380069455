import { Dispatch, SetStateAction } from 'react';
// @types
import { UpdateSettings } from 'src/@types/site';
// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import DeactivateAutoUpdatesConfirmation from './DeactivateAutoUpdatesConfirmation';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  setCurrentSettings: Dispatch<SetStateAction<UpdateSettings>>;
};

// ----------------------------------------------------------------------

export default function DeactivateAutoUpdatesDialog({ open, onClose, setCurrentSettings }: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={
        <DeactivateAutoUpdatesConfirmation
          onClose={onClose}
          setCurrentSettings={setCurrentSettings}
        />
      }
    />
  );
}

// @types
import { PluginThemeAutoUpdate } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

type Props = {
  currentIncludedAutoUpdatesSettings: PluginThemeAutoUpdate[];
  row: PluginThemeAutoUpdate;
  handleSelectRow: (id: string, checked: boolean) => void;
};

// ----------------------------------------------------------------------

export default function IncludedAutoUpdatesTableRow({
  currentIncludedAutoUpdatesSettings,
  row,
  handleSelectRow,
}: Props) {
  // HOOK
  const { translate } = useLocales();

  // VAR
  const { type, title, version, included } = row;

  const rowId: string = `${type}${title}`;

  const currentSavedRowInfo: PluginThemeAutoUpdate | undefined =
    currentIncludedAutoUpdatesSettings.find((update) => `${update.type}${update.title}` === rowId);

  return (
    <tr>
      <td>
        <input
          type="checkbox"
          className="gv-checkbox"
          checked={included}
          onChange={(e) => handleSelectRow(rowId, e.target.checked)}
        />
      </td>
      <td>{title}</td>
      <td>{translate(`dashboard.sites.details.mwp.updates.type.${type}`)}</td>
      <td>{version}</td>
      <td>
        {/* Only update "Excluded" text after table has been saved */}
        {currentSavedRowInfo && !currentSavedRowInfo.included && (
          <p>
            {translate(
              'dashboard.sites.details.mwp.updates.pluginAndThemeUpdates.includedAutoUpdatesSection.table.row.status'
            )}
          </p>
        )}
      </td>
    </tr>
  );
}

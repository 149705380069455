import { Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import ReactGA from 'react-ga4';
// form
import { UseFormSetValue, useForm } from 'react-hook-form';
// @types
import {
  CustomScheduleDayTimeEnum,
  CustomScheduleFrequencyEnum,
  CustomScheduleWeekTimeEnum,
  CustomScheduleSettings,
} from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { getAllTimeZones } from 'src/utils/getTimeZones';
// components
import { FormProvider, RHFAutocomplete, RHFRadioGroup } from 'src/components/gravity/hook-form';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';

// ----------------------------------------------------------------------

const TIME_OF_DAY_LABELS = {
  morning: '03:00 AM - 08:59 AM',
  noon: '09:00 AM - 02:59 PM',
  afternoon: '03:00 PM - 08:59 PM',
  evening: '09:00 PM - 02:59 AM',
};

// ----------------------------------------------------------------------

type FormValuesProps = {
  frequency: CustomScheduleFrequencyEnum;
  timeOfWeek: CustomScheduleWeekTimeEnum;
  timeOfDay: CustomScheduleDayTimeEnum;
  timeZone: string;
  afterSubmit?: string;
};

type Props = {
  currentScheduleSettings: CustomScheduleSettings;
  setCurrentScheduleSettings: Dispatch<SetStateAction<CustomScheduleSettings>>;
  setSaveCustomScheduleSuccess: Dispatch<SetStateAction<undefined | boolean>>;
};

// ----------------------------------------------------------------------

export default function CustomScheduleForm({
  currentScheduleSettings,
  setCurrentScheduleSettings,
  setSaveCustomScheduleSuccess,
}: Props) {
  const { cluster, namespace, name } = useParams();

  const navigate = useNavigate();

  // HOOK
  const { translate } = useLocales();

  // STATE
  const [isLoading, setisLoading] = useState<boolean>(false);

  // VAR
  const { frequency, timeOfWeek, timeOfDay, timeZone } = currentScheduleSettings.settings;

  // FORM
  const defaultValues = {
    frequency: frequency,
    timeOfWeek: timeOfWeek,
    timeOfDay: timeOfDay,
    timeZone: timeZone,
  };

  const methods = useForm<FormValuesProps>({
    defaultValues,
  });

  const { handleSubmit, watch, setValue } = methods;

  const frequencyInputValue = watch('frequency');

  // EVENT FUNCTION
  const onSubmit = async (data: FormValuesProps) => {
    if (
      frequency === data.frequency &&
      timeOfWeek === data.timeOfWeek &&
      timeOfDay === data.timeOfDay &&
      timeZone === data.timeZone
    )
      return;

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'save-custom-schedule-settings',
    });

    setisLoading(true);
    setTimeout(() => {
      setisLoading(false);

      const isSuccess: boolean = false;

      if (isSuccess) {
        setCurrentScheduleSettings((prev) => ({
          ...prev,
          customSettings: data,
        }));
      }

      setSaveCustomScheduleSuccess(isSuccess);
    }, 2000);
  };

  //
  useEffect(() => {
    if (frequencyInputValue === CustomScheduleFrequencyEnum.DAILY) {
      setValue('timeOfWeek', CustomScheduleWeekTimeEnum.NO_PREFERENCE);
    }
  }, [frequencyInputValue, setValue]);

  return (
    <div className="gv-w-full">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <div className="gv-content-container gv-flex-column-md gv-p-lg">
          <FormSection
            title="dashboard.sites.details.mwp.updates.updateSchedule.customScheduleSection.form.frequency.title"
            description="dashboard.sites.details.mwp.updates.updateSchedule.customScheduleSection.form.frequency.description"
            formField={
              <RHFRadioGroup
                name="frequency"
                options={Object.values(CustomScheduleFrequencyEnum).map((val) => ({
                  value: val,
                  label: translate(
                    `dashboard.sites.details.mwp.updates.updateSchedule.customScheduleSection.form.frequency.options.${val}`
                  ),
                }))}
                condensed
              />
            }
          />

          <FormSection
            title="dashboard.sites.details.mwp.updates.updateSchedule.customScheduleSection.form.timeOfWeek.title"
            description="dashboard.sites.details.mwp.updates.updateSchedule.customScheduleSection.form.timeOfWeek.description"
            formField={
              <RHFRadioGroup
                name="timeOfWeek"
                options={Object.values(CustomScheduleWeekTimeEnum).map((val) => ({
                  value: val,
                  label: translate(
                    `dashboard.sites.details.mwp.updates.updateSchedule.customScheduleSection.form.timeOfWeek.options.${val}`
                  ),
                  disabled:
                    val !== CustomScheduleWeekTimeEnum.NO_PREFERENCE &&
                    frequencyInputValue === CustomScheduleFrequencyEnum.DAILY,
                }))}
                condensed
              />
            }
          />

          <FormSection
            title="dashboard.sites.details.mwp.updates.updateSchedule.customScheduleSection.form.timeOfDay.title"
            description="dashboard.sites.details.mwp.updates.updateSchedule.customScheduleSection.form.timeOfDay.description"
            formField={
              <RHFRadioGroup
                name="timeOfDay"
                options={Object.values(CustomScheduleDayTimeEnum).map((val) => ({
                  value: val,
                  label: TIME_OF_DAY_LABELS[val],
                }))}
                condensed
              />
            }
          />

          <TimeZoneAutocomplete timeZoneValue={timeZone} setValue={setValue} />

          <ButtonGroup>
            <Button
              uiType="cancel"
              text={translate('wpone.general.action.back')}
              onClick={() => navigate(`/sites/updates/${cluster}/${namespace}/${name}/settings`)}
            />
            <Button
              type="submit"
              text={translate('dashboard.general.action.save')}
              disabled={isLoading}
            />
          </ButtonGroup>
        </div>
      </FormProvider>
    </div>
  );
}

// ----------------------------------------------------------------------

type FormSectionProps = {
  title: string;
  description: string;
  formField: ReactNode;
};

// ----------------------------------------------------------------------

function FormSection({ title, description, formField }: FormSectionProps) {
  // HOOK
  const { translate } = useLocales();

  return (
    <div className="gv-flex-column-sm">
      <p className="gv-text-sm gv-text-bold">{translate(title)}</p>
      <p className="gv-text-sm">{translate(description)}</p>
      {formField}
    </div>
  );
}

// ----------------------------------------------------------------------

type TimeZoneAutocompleteProps = {
  timeZoneValue: string;
  setValue: UseFormSetValue<FormValuesProps>;
};

// ----------------------------------------------------------------------

function TimeZoneAutocomplete({ timeZoneValue, setValue }: TimeZoneAutocompleteProps) {
  // HOOK
  const { translate } = useLocales();

  // STATE
  const [timeZoneInput, setTimeZoneInput] = useState<string>(timeZoneValue);
  const [selectedOption, setSelectedOption] = useState<string>(timeZoneValue);

  // VAR
  const allTimeZones = getAllTimeZones();

  const searchSitesResult = useMemo(
    () =>
      allTimeZones.filter((result) => result.toLowerCase().includes(timeZoneInput.toLowerCase())),
    [timeZoneInput, allTimeZones]
  );

  // EVENT FUNCTION
  function handleSelectOptionChange(searchInput: string) {
    setSelectedOption(searchInput);
    setValue('timeZone', searchInput);
  }

  return (
    <RHFAutocomplete
      name="timeZone"
      id="timeZone"
      label={translate(
        'dashboard.sites.details.mwp.updates.updateSchedule.customScheduleSection.form.timeZone.label'
      )}
      inputValue={timeZoneInput}
      selectedOption={selectedOption}
      isLoading={false}
      isError={false}
      options={searchSitesResult}
      setInputValue={setTimeZoneInput}
      handleSelectOptionChange={handleSelectOptionChange}
    />
  );
}

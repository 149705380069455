import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { setBannerHeight, toggleActionsInProgressBanner } from 'src/redux/features/layout';
import { setCurrentMigrationDetails } from 'src/redux/features/migration';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { fShortDateTime, fHttpPrefix } from 'src/utils/format';
// components
import { TextWithNavigationLink } from 'src/components/gravity/text';

// ----------------------------------------------------------------------

export default function ActionsInProgressBanner() {
  const location = useLocation();

  const dispatch = useDispatch();

  const bannerRef = useRef<HTMLDivElement>(null);

  // HOOK
  const { translate, currentLang } = useLocales();

  // STATE
  const { migrationsInProgress } = useSelector((state) => state.migration);
  const { backupsInProgress } = useSelector((state) => state.backup);

  // HELPER FUNCTION
  function displayActionsBanner(): boolean {
    // Hide banner if:
    // 1. No migration AND backup in progress
    // 2. ONLY migration in progress but is currently in sites migration page
    // 3. ONLY backup in progress but is currently in sites backups page

    const { pathname } = window.location;

    const migrationNumber = migrationsInProgress.length;
    const backupNumber = backupsInProgress.length;

    const isMigrationPage = pathname.startsWith('/sites/migration');
    const isBackupPage = pathname.startsWith('/sites/backups');

    if (
      (migrationNumber === 0 && backupNumber === 0) ||
      (isMigrationPage && backupNumber === 0) ||
      (isBackupPage && migrationNumber === 0)
    ) {
      return false;
    }

    return true;
  }

  //
  useEffect(() => {
    function handleResize() {
      dispatch(
        setBannerHeight(bannerRef?.current?.clientHeight ? bannerRef.current.clientHeight : 0)
      );
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch, location]);

  return displayActionsBanner() ? (
    <div className="gv-activated">
      <div className="gv-notice gv-notice-info gv-mode-condensed" ref={bannerRef}>
        <gv-icon class="gv-notice-icon" src="/src/icons/error.svg" />
        <div className="gv-notice-content">
          {!window.location.pathname.startsWith('/sites/migration') &&
            migrationsInProgress.map((migrationDetails) => (
              <TextWithNavigationLink
                key={migrationDetails.destinationSite}
                text={translate('wpone.sites.migration.bannerText', {
                  sourceUrl: fHttpPrefix(migrationDetails.sourceSite),
                  destinationUrl: migrationDetails.destinationSite,
                })}
                linkHrefs={['/sites/migration']}
                onClick={() => {
                  dispatch(setCurrentMigrationDetails(migrationDetails));
                }}
                linkStyleClassName="gv-text-bold"
                textExtraClass=" gv-caption-lg"
              />
            ))}

          {!window.location.pathname.startsWith('/sites/backups') &&
            backupsInProgress.map(({ settings }) => (
              <TextWithNavigationLink
                key={settings.hostname}
                text={translate(`wpone.sites.details.backups.banner.${settings.method}`, {
                  date: fShortDateTime(settings.date, { locale: currentLang.value }),
                  siteName: settings.hostname,
                })}
                linkHrefs={[
                  `/sites/backups/${settings.cluster}/${settings.namespace}/${settings.hostname}/details`,
                ]}
                linkStyleClassName="gv-text-bold"
                textExtraClass=" gv-caption-lg"
              />
            ))}
        </div>
        <button
          className="gv-notice-close"
          onClick={() => dispatch(toggleActionsInProgressBanner(false))}
        >
          <gv-icon src="/src/icons/close.svg" />
        </button>
      </div>
    </div>
  ) : (
    <></>
  );
}

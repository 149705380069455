import { useEffect } from 'react';
import { useParams } from 'react-router';
import { Outlet } from 'react-router-dom';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { setTabConfig, setSiteBrand } from 'src/redux/features/layout';
import { useGetSiteQuery, useGetWebspaceTokenQuery } from 'src/redux/api/siteApi';
// @types
import { UserRoleEnum } from 'src/@types/user';
import {
  InstallationProvider,
  MWPSiteStatusEnum,
  SiteBillingEnum,
  SitePlanEnum,
  SiteStatusEnum,
  SiteBrandEnum,
} from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
import useHighestUserRole from 'src/hooks/useHighestUserRole';
// utils
import { handleZendeskChatOpen } from 'src/utils/zendesk';
// mock
import { DUMMY_STATUS_ISSUE } from 'src/mock/sites';
import { filterAllowedSiteDetailsTabs } from 'src/utils/layout';
// config
import { PAGE_DETAILS_TAB_CONFIG } from 'src/config';
// components
import { DetailsPageHeader, Page, SidebarPage } from 'src/components/gravity/page';
import Button from 'src/components/gravity/Button';

// ----------------------------------------------------------------------

export default function SiteDetailsLayout() {
  const { name, cluster, namespace } = useParams();

  const dispatch = useDispatch();

  // HOOK
  const { highestUserRole } = useHighestUserRole();

  // STATE
  const { user } = useSelector((state) => state.auth);

  // API
  const { data, isFetching } = useGetSiteQuery({
    cluster: cluster as string,
    namespace: namespace as string,
  });

  const { data: webspaceToken, isFetching: isSiteWebspaceTokenFetching } = useGetWebspaceTokenQuery(
    { namespace: namespace as string, cluster: cluster as string }
  );

  // VAR
  // Change the index number between 0 and a number from 1-13 in order to check the incomplete installation page
  // 0: incomplete
  // 1 - 3: up
  // 4 - 10: down
  // 11: migration
  // 12 - 13: suspended
  const { status } = DUMMY_STATUS_ISSUE[1];

  //
  useEffect(() => {
    if (
      isFetching ||
      isSiteWebspaceTokenFetching ||
      !data ||
      !user ||
      (data.state === SiteStatusEnum.suspended && user.role === UserRoleEnum.customer)
    )
      return;

    const tabConfig =
      data.provider === InstallationProvider.WP_ONE
        ? PAGE_DETAILS_TAB_CONFIG.sites.wpone
        : PAGE_DETAILS_TAB_CONFIG.sites.mwp;

    const navConfig = tabConfig.navConfig.filter((nav) => {
      // Extra filter for hiding plan tab for even support role (WP.one)
      const hidePlanTab =
        data.provider === InstallationProvider.WP_ONE &&
        (data.plan.name === SitePlanEnum.internal || data.billing.name === SiteBillingEnum.none) &&
        nav.tabName === 'plan';

      // Extra filter for hiding chatbot tab when webspace token is not available
      const hideChatbotTab = !webspaceToken?.webspace_api_token && nav.tabName === 'chatbot';

      return !hidePlanTab && !hideChatbotTab;
    });

    dispatch(
      setTabConfig({
        ...tabConfig,
        navConfig: filterAllowedSiteDetailsTabs(navConfig, user, highestUserRole),
      })
    );

    dispatch(setSiteBrand(data.brand.name as SiteBrandEnum | undefined));

    return () => {
      dispatch(setTabConfig(undefined));
      dispatch(setSiteBrand(undefined));
    };
  }, [
    isFetching,
    dispatch,
    data,
    user,
    highestUserRole,
    isSiteWebspaceTokenFetching,
    webspaceToken?.webspace_api_token,
  ]);

  if (!user || isFetching || !data) return <></>;

  if (data.provider === InstallationProvider.WP_ONE) {
    if (data.state === SiteStatusEnum.suspended && user.role === UserRoleEnum.customer) {
      return (
        <SiteIssuePage
          title="wpone.sites.details.overview.title"
          name={name as string}
          header="wpone.sites.details.overview.suspended.title"
          description="wpone.sites.details.overview.suspended.description"
          ctaBtn={{
            text: 'wpone.general.action.contactSupport',
            onClick: () => handleZendeskChatOpen(user?.brand?.id),
          }}
          breadcrumbs={false}
        />
      );
    }
    return (
      <SidebarPage title={`${name}`}>
        <Outlet />
      </SidebarPage>
    );
  }

  if (data.provider === InstallationProvider.ONE_HOP) {
    if (status === MWPSiteStatusEnum.INCOMPLETE) {
      return (
        <SiteIssuePage
          title="dashboard.sites.details.mwp.overview.title"
          name={name as string}
          header="dashboard.sites.details.mwp.overview.incompleteInstallation.title"
          description="dashboard.sites.details.mwp.overview.incompleteInstallation.description"
          ctaBtn={{
            text: 'dashboard.sites.details.mwp.overview.incompleteInstallation.action',
            onClick: () => console.log('Continue onboarding'),
          }}
        />
      );
    }
    return (
      <SidebarPage title={`${name}`}>
        <Outlet />
      </SidebarPage>
    );
  }

  return <></>;
}

// ----------------------------------------------------------------------

type SiteIssuePageProps = {
  title: string;
  name: string;
  header: string;
  description: string;
  ctaBtn: {
    text: string;
    onClick: VoidFunction;
  };
  breadcrumbs?: boolean;
};

// ----------------------------------------------------------------------
// FOR WPONE SUSPENDED SITE AND MWP INCOMPLETE INSTALLATION

function SiteIssuePage({
  title,
  name,
  header,
  description,
  ctaBtn: { text, onClick },
  breadcrumbs = true,
}: SiteIssuePageProps) {
  // HOOK
  const { translate } = useLocales();

  return (
    <Page title={`${name}`}>
      <div className="gv-flex gv-flex-col gv-gap-fluid">
        <DetailsPageHeader title={title} breadcrumbs={breadcrumbs} />

        <div className="gv-flex-column-lg gv-items-center gv-p-fluid">
          <div className="gv-flex-column-sm gv-items-center">
            <p className="gv-heading-sm">{translate(header)}</p>
            <p className="gv-text-md gv-text-center">{translate(description)}</p>
          </div>
          <Button text={translate(text)} onClick={onClick} />
        </div>
      </div>
    </Page>
  );
}

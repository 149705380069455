import { Dispatch, SetStateAction, useState } from 'react';
import ReactGA from 'react-ga4';
// @types
import { VisualTestingSettings } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// utils
import { displayToast } from 'src/utils/handleToast';
// components
import { Toggle } from 'src/components/gravity/form';
import Alert from 'src/components/gravity/Alert';
//
import SettingsSection from '../SettingsSection';
import AdvanceVisualTestingForm from './AdvanceVisualTestingForm';

// ----------------------------------------------------------------------

type Props = {
  currentVisualTestingSettings: VisualTestingSettings;
  setCurrentVisualTestingSettings: Dispatch<SetStateAction<VisualTestingSettings>>;
};

// ----------------------------------------------------------------------

export default function VisualUpdateTestingSection({
  currentVisualTestingSettings,
  setCurrentVisualTestingSettings,
}: Props) {
  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  // STATE
  const [isLoading, setisLoading] = useState<boolean>(false);

  const [saveVisualTestingSettingsSuccess, setSaveVisualTestingSettingsSuccess] = useState<
    boolean | undefined
  >(undefined);

  // EVENT FUNCTION
  async function handleToggleVisualTesting(value: boolean) {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'toggle-visual-testing',
    });

    setisLoading(true);
    try {
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve('Fetch successful');
          // reject('Fetch failed');
        }, 3000);
      });

      const isSuccess: boolean = true;

      if (value) {
        if (isSuccess) {
          displayToast(
            translate(
              'dashboard.sites.details.mwp.updates.visualUpdateTesting.visualUpdateTestingSection.toast.activate.success'
            )
          );
          setCurrentVisualTestingSettings((prev) => ({ ...prev, activate: value }));
        } else {
          displayToast(
            translate(
              'dashboard.sites.details.mwp.updates.visualUpdateTesting.visualUpdateTestingSection.toast.activate.error'
            ),
            {
              variant: 'alert',
            }
          );
        }
      } else {
        if (isSuccess) {
          displayToast(
            translate(
              'dashboard.sites.details.mwp.updates.visualUpdateTesting.visualUpdateTestingSection.toast.deactivate.success'
            )
          );
          setCurrentVisualTestingSettings((prev) => ({ ...prev, activate: value }));
        } else {
          displayToast(
            translate(
              'dashboard.sites.details.mwp.updates.visualUpdateTesting.visualUpdateTestingSection.toast.deactivate.error'
            ),
            {
              variant: 'alert',
            }
          );
        }
      }
    } finally {
      setisLoading(false);
    }
  }

  return (
    <SettingsSection
      title={
        <div
          className="gv-flex-row-sm gv-items-center"
          style={{
            width: '100%',
            justifyContent: isMobile ? 'space-between' : 'flex-start',
          }}
        >
          <p className="gv-text-lg gv-text-bold">
            {translate(
              'dashboard.sites.details.mwp.updates.visualUpdateTesting.visualUpdateTestingSection.title'
            )}
          </p>
          <Toggle
            labelId="activate"
            label={translate(
              'dashboard.sites.details.mwp.updates.visualUpdateTesting.visualUpdateTestingSection.actionLabel'
            )}
            condensed
            disabled={isLoading}
            checked={currentVisualTestingSettings.activate}
            onChange={(e) => handleToggleVisualTesting(e.currentTarget.checked)}
          />
        </div>
      }
      description={translate(
        'dashboard.sites.details.mwp.updates.visualUpdateTesting.visualUpdateTestingSection.description'
      )}
      alert={
        saveVisualTestingSettingsSuccess !== undefined ? (
          <Alert
            type={saveVisualTestingSettingsSuccess ? 'success' : 'alert'}
            text={translate(
              `dashboard.sites.details.mwp.updates.visualUpdateTesting.visualUpdateTestingSection.alert.${
                saveVisualTestingSettingsSuccess ? 'success' : 'error'
              }`
            )}
            handleClose={() => setSaveVisualTestingSettingsSuccess(undefined)}
          />
        ) : undefined
      }
    >
      {currentVisualTestingSettings.activate && (
        <AdvanceVisualTestingForm
          currentVisualTestingSettings={currentVisualTestingSettings}
          setCurrentVisualTestingSettings={setCurrentVisualTestingSettings}
          setSaveVisualTestingSettingsSuccess={setSaveVisualTestingSettingsSuccess}
        />
      )}
    </SettingsSection>
  );
}

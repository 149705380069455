import { createApi } from '@reduxjs/toolkit/query/react';
// @types
import {
  UserCreateResponse,
  UserCreateRequest,
  UserUpdateResponse,
  UserProfileUpdateRequest,
  UserGetListResponse,
  ChangeUserResourceLimitsRequest,
  UserDTO,
  UserGetResponse,
  UpdateLastReadFeatureVersionRequest,
  UserSiteColumnsUpdate,
  UserLocaleUpdateRequest,
  UserDeleteResponse,
  UserDeleteRequest,
  DiskSiteUsageDTO,
  AddSshKeyRequest,
  RemoveSshKeyRequest,
} from '@joonasvanhatapio/wp-cloud-backend-types';
// utils
import { convertToIDN, convertToUnicode } from 'src/utils/convert';
//
import { axiosBaseQuery } from './axiosBaseQuery';

// ----------------------------------------------------------------------

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/v5/user' }),
  tagTypes: ['User'],
  endpoints: (builder) => ({
    // GET CURRENT USER
    getCurrentUser: builder.query<UserGetResponse, string | undefined>({
      query: (email) => {
        const searchParams = new URLSearchParams();

        searchParams.append('sitesStatistic', 'detailed');

        if (email) {
          searchParams.append('email', email);
        }

        const searchParamsString = searchParams.toString();

        return {
          url: `/get?${searchParamsString}`,
          method: 'get',
        };
      },
      transformResponse: (response: UserGetResponse) => ({
        ...response,
        sitesStatistic: response.sitesStatistic
          ? ({
              ...response.sitesStatistic,
              detailed: response.sitesStatistic.detailed?.map((site) => ({
                ...site,
                hostname: convertToUnicode(site.hostname),
              })),
            } as DiskSiteUsageDTO)
          : undefined,
        accessPermissions: response.accessPermissions?.map((permission) => ({
          ...permission,
          resource: {
            ...permission.resource,
            externalId: convertToUnicode(permission.resource.externalId),
          },
        })),
      }),
      providesTags: (result) => (result ? [{ type: 'User', id: 'PARTIAL-LIST' }] : []),
    }),
    // GET USER LIST
    getUsers: builder.query<
      UserGetListResponse,
      {
        query: string;
        externalId: string;
        sortType: string;
        sortField: string;
        rowsPerPage: number;
        page: number;
        plan: string;
        lastLoggedIn: boolean;
      }
    >({
      query: ({
        query,
        externalId,
        sortType,
        sortField,
        rowsPerPage,
        page,
        plan,
        lastLoggedIn,
      }) => {
        const searchParams = new URLSearchParams();

        searchParams.append('page', page.toString());
        searchParams.append('limit', rowsPerPage.toString());
        searchParams.append('query', query);
        searchParams.append('externalId', convertToIDN(externalId));
        searchParams.append('sortType', sortType);
        searchParams.append('sortField', sortField);
        searchParams.append('lastLoggedIn', lastLoggedIn.toString());

        if (plan !== 'all') {
          searchParams.append('plan', plan);
        }

        const searchParamsString = searchParams.toString();

        return {
          url: `/list?${searchParamsString}`,
          method: 'get',
        };
      },
      transformResponse: (response: UserGetListResponse) => ({
        ...response,
        items: response.items.map((item) => ({
          ...item,
          sitesStatistic: item.sitesStatistic
            ? ({
                ...item.sitesStatistic,
                detailed: item.sitesStatistic.detailed?.map((site) => ({
                  ...site,
                  hostname: convertToUnicode(site.hostname),
                })),
              } as DiskSiteUsageDTO)
            : undefined,
          accessPermissions: item.accessPermissions?.map((permission) => ({
            ...permission,
            resource: {
              ...permission.resource,
              externalId: convertToUnicode(permission.resource.externalId),
            },
          })),
        })),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({ type: 'User' as const, id: id })),
              { type: 'User', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'User', id: 'PARTIAL-LIST' }],
    }),
    //CREATE USER
    createUser: builder.mutation<UserCreateResponse, UserCreateRequest>({
      query: (data) => ({
        url: `/create`,
        method: 'post',
        data: data,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'User', id: 'PARTIAL-LIST' }] : []),
    }),
    //CHANGE RESOURCES LIMIT
    changeResourcesLimit: builder.mutation<UserDTO, ChangeUserResourceLimitsRequest>({
      query: ({ id, userResourceLimits }) => ({
        url: `/change-limits`,
        method: 'post',
        data: {
          id: id,
          userResourceLimits: userResourceLimits,
        },
      }),
      invalidatesTags: (result) => (result ? [{ type: 'User', id: 'PARTIAL-LIST' }] : []),
    }),
    //ADD SSH KEY
    addSSHKey: builder.mutation<UserUpdateResponse, AddSshKeyRequest>({
      query: ({ sshKey }) => ({
        url: `/add-ssh-key`,
        method: 'put',
        data: {
          sshKey: sshKey,
        },
      }),
      invalidatesTags: (result) => (result ? [{ type: 'User', id: 'PARTIAL-LIST' }] : []),
    }),
    //REMOVE SSH KEY
    removeSSHKey: builder.mutation<UserUpdateResponse, RemoveSshKeyRequest>({
      query: ({ sshKey }) => ({
        url: `/remove-ssh-key`,
        method: 'put',
        data: {
          sshKey: sshKey,
        },
      }),
      invalidatesTags: (result) => (result ? [{ type: 'User', id: 'PARTIAL-LIST' }] : []),
    }),
    //UPDATE USER PROFILE
    updateUserProfile: builder.mutation<UserUpdateResponse, UserProfileUpdateRequest>({
      query: ({ name, notifyEmail, notifications, uptimeMonitorNotification }) => ({
        url: `/update-profile`,
        method: 'put',
        data: {
          name: name,
          notifyEmail: notifyEmail,
          notifications: notifications,
          uptimeMonitorNotification: uptimeMonitorNotification,
        },
      }),
      invalidatesTags: (result) => (result ? [{ type: 'User', id: 'PARTIAL-LIST' }] : []),
    }),
    //UPDATE USER LOCALE
    updateUserLocale: builder.mutation<UserUpdateResponse, UserLocaleUpdateRequest>({
      query: ({ locale }) => ({
        url: `/update-locale`,
        method: 'put',
        data: {
          locale: locale,
        },
      }),
      invalidatesTags: (result) => (result ? [{ type: 'User', id: 'PARTIAL-LIST' }] : []),
    }),
    //UPDATE USER LAST READ FEATURE
    updateUserLastReadFeature: builder.mutation<
      UserUpdateResponse,
      UpdateLastReadFeatureVersionRequest
    >({
      query: (data) => ({
        url: `/update-last-viewed-version`,
        method: 'put',
        data,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'User', id: 'PARTIAL-LIST' }] : []),
    }),
    //UPDATE SELECTED SITE COLUMNS
    updateSelectedSiteColumns: builder.mutation<UserUpdateResponse, UserSiteColumnsUpdate>({
      query: (data) => ({
        url: `/update-selected-site-columns`,
        method: 'put',
        data,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'User', id: 'PARTIAL-LIST' }] : []),
    }),
    // DELETE USER WHO HAS 0 SITES AND 0 ZONES ACCESS
    deleteUser: builder.mutation<UserDeleteResponse, UserDeleteRequest>({
      query: (data) => ({
        url: `/delete`,
        method: 'delete',
        data,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'User', id: 'PARTIAL-LIST' }] : []),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useCreateUserMutation,
  useChangeResourcesLimitMutation,
  useAddSSHKeyMutation,
  useRemoveSSHKeyMutation,
  useUpdateUserProfileMutation,
  useUpdateUserLocaleMutation,
  useUpdateUserLastReadFeatureMutation,
  useUpdateSelectedSiteColumnsMutation,
  useDeleteUserMutation,
} = userApi;

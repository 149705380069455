import axios, { AxiosError, AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
// redux
import { store, persistor } from 'src/redux/store';
import { setUser } from 'src/redux/features/auth';
// config
import { HOST_API } from 'src/config';
// ----------------------------------------------------------------------

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const isChatBotApi = config.url?.includes('api.synohq.com');

  const storedAccessToken = localStorage.getItem('access_token');

  if (config.headers === undefined) {
    config.headers = {};
  }

  if (
    window.location.hostname === 'localhost' &&
    window.location.port === '3000' &&
    storedAccessToken &&
    !isChatBotApi
  ) {
    config.headers.Authorization = `Bearer ${JSON.parse(storedAccessToken)}`;
  }

  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

const onResponseError = async (error: AxiosError) => {
  const { response, config } = error;

  const isChatBotApi = response?.config.url?.includes('api.synohq.com');

  if (!isChatBotApi) {
    switch (response?.status) {
      // Access Token was expired
      case 401:
        if (response.statusText === 'Unauthorized') {
          const storedRefreshToken = JSON.parse(localStorage.getItem('refresh_token') || '{}');

          try {
            // If the request is from localhost:3000, use access token as authentication method
            if (window.location.hostname === 'localhost' && window.location.port === '3000') {
              const axiosInst = axios.create({ baseURL: HOST_API });

              const rs = await axiosInst({
                url: '/api/v5/refresh',
                method: 'post',
                headers: { 'x-auth-refresh-token': storedRefreshToken },
              });

              const { access_token, refresh_token } = rs.data;

              if (config.headers === undefined) {
                config.headers = {};
              }
              config.headers.Authorization = `Bearer ${access_token}`;

              localStorage.setItem('refresh_token', JSON.stringify(refresh_token));
              localStorage.setItem('access_token', JSON.stringify(access_token));

              return await axios(config);
            } else {
              // If the request is from other domains, use credentials
              const axiosInst = axios.create({ baseURL: HOST_API, withCredentials: true });

              const rs = await axiosInst({
                url: '/api/v5/refresh',
                method: 'post',
                headers: { 'x-auth-refresh-token': storedRefreshToken },
              });

              const { refresh_token } = rs.data;

              localStorage.setItem('refresh_token', JSON.stringify(refresh_token));

              return await axios(config);
            }
          } catch (_error) {
            if (store.getState().auth.user) {
              window.location.reload();
            }

            if (window.location.hostname === 'localhost' && window.location.port === '3000') {
              localStorage.removeItem('access_token');
            }

            localStorage.removeItem('refresh_token');
            store.dispatch(setUser(null));
            persistor.purge();

            return Promise.reject(_error);
          }
        }
        break;

      case 403:
        if (response.statusText === 'Forbidden' && config.method === 'get') {
          // Only redirect to 403 page when user trying to get data they do not have access to (GET request)
          window.location.replace('/403');
        }
        break;
    }
  }

  return Promise.reject(error);
};

const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use((response: AxiosResponse) => response, onResponseError);
  return axiosInstance;
};

const axiosInstance: AxiosInstance = setupInterceptorsTo(
  axios.create({
    baseURL: HOST_API,
    headers: {
      'Content-Type': 'application/json',
    },
  })
);

export default axiosInstance;

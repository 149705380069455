import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
// redux
import { useSelector } from 'src/redux/store';
// @types
import { UserRoleEnum } from 'src/@types/user';
import { SiteBrandEnum } from 'src/@types/site';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import useLocales from 'src/hooks/useLocales';
// utils
import { fCapitalizeFirstLetter } from 'src/utils/format';
// config
import { HEADER } from 'src/config';
//
import Badge from '../Badge';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
  title: string;
  meta?: ReactNode;
};

// ----------------------------------------------------------------------

const SidebarPage = forwardRef<HTMLDivElement, Props>(({ children, title = '', meta }, ref) => {
  const { pathname } = useLocation();

  const { cluster, namespace, name } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isDesktop = useResponsive('up', 'desktop_min');

  // STATE
  const {
    footerHeight,
    overflowPadding,
    sidebarHighlightActivePage,
    tabConfig,
    siteBrand,
    bannerHeight,
  } = useSelector((state) => state.layout);

  const { user } = useSelector((state) => state.auth);

  // VAR
  const tabname = pathname.split('/')[2];

  return (
    <>
      <Helmet>
        <title>{`${title} | WP.one`}</title>
        {meta}
      </Helmet>

      <div className="gv-activated">
        <div
          className="gv-flex gv-justify-center"
          style={{
            minHeight: `calc(100vh - ${HEADER.HEIGHT + bannerHeight + footerHeight}px)`,
            paddingRight: overflowPadding,
            backgroundColor: 'var(--color-surface-dim)',
          }}
        >
          <div
            className="gv-flex gv-flex-row gv-w-full"
            style={{
              maxWidth: 1536,
            }}
          >
            {isDesktop && tabConfig && (
              <div className="gv-sidebar gv-py-fluid">
                {user?.role === UserRoleEnum.support && siteBrand && (
                  <Badge
                    type="info"
                    text={
                      siteBrand === SiteBrandEnum.metanet
                        ? siteBrand.toUpperCase()
                        : fCapitalizeFirstLetter(siteBrand)
                    }
                  />
                )}
                <div className="gv-side-menu">
                  <div className="gv-side-label">{translate(tabConfig.title)}</div>
                  <nav className="gv-side-nav">
                    <ul>
                      {tabConfig.navConfig.map((tab, index) => (
                        <RouterLink
                          key={index}
                          to={
                            tabConfig.primaryPathname === 'sites'
                              ? `/${tabConfig.primaryPathname}/${tab.tabName}/${cluster}/${namespace}/${name}`
                              : tabConfig.primaryPathname === 'dns'
                              ? `/${tabConfig.primaryPathname}/${tab.tabName}/${name}`
                              : `/${tabConfig.primaryPathname}/${tab.tabName}`
                          }
                          className={`gv-nav-item${
                            tab.tabName === tabname && sidebarHighlightActivePage
                              ? ' gv-active'
                              : ''
                          }`}
                        >
                          <gv-icon src={`/src/icons/${tab.icon}.svg`} />
                          <span className="gv-text-truncate">{translate(tab.title)}</span>
                        </RouterLink>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>
            )}

            <div
              ref={ref}
              className='gv-p-fluid gv-w-full'
              style={{
                maxWidth: isDesktop ? `calc(100% - 232px)` : '100%',
              }}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default SidebarPage;

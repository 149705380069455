import { useEffect } from 'react';
// redux
import { useSelector } from 'src/redux/store';
// @types
import { UserRoleEnum } from 'src/@types/user';

// ----------------------------------------------------------------------

export default function Hotjar() {
  // STATE
  const { user } = useSelector((state) => state.auth);

  // VAR
  const nonSupportUserMemberArr: string[] = [];

  //
  useEffect(() => {
    const script = document.createElement('script');

    if (
      user &&
      user.role !== UserRoleEnum.support &&
      !nonSupportUserMemberArr.includes(user.email)
    ) {
      script.textContent = `
          (function (h, o, t, j, a, r) {
            h.hj =
              h.hj ||
              function () {
                (h.hj.q = h.hj.q || []).push(arguments);
              };
            h._hjSettings = { hjid: 3535104, hjsv: 6 };
            a = o.getElementsByTagName('head')[0];
            r = o.createElement('script');
            r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
          })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
        `;

      document.body.appendChild(script);
    }

    return () => {
      document.body.removeChild(script); // Cleanup when unmounting
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}

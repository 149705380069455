// @types
import { TableHeader } from 'src/@types/layout';
import { SitePlanEnum } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// components
import { TableHeadCustom, TableSkeleton } from 'src/components/gravity/table';

// ----------------------------------------------------------------------

const HEADERS: TableHeader[] = [
  { id: 'plan', label: 'wpone.sites.details.plan.table.header.plan' },
  { id: 'diskSpace', label: 'wpone.sites.details.plan.table.header.diskSpace' },
  { id: 'planStorageLimit', label: 'wpone.sites.details.plan.table.header.planStorageLimit' },
];

// ----------------------------------------------------------------------

type Props = {
  isFetching: boolean;
  isBoosted: boolean;
  currentPlanName: SitePlanEnum | undefined;
  previousPlanName: SitePlanEnum | undefined;
  localDiskUsage: number;
  localDiskLimit: number;
  planDiskLimit: number;
};

// ----------------------------------------------------------------------

export default function PlanTable({
  isFetching,
  isBoosted,
  currentPlanName,
  previousPlanName,
  localDiskUsage,
  localDiskLimit,
  planDiskLimit,
}: Props) {
  // HOOK
  const { translate } = useLocales();

  // HELPER FUNCTION
  function getDiskSpaceDisplayText(): string {
    /**
     * Get the display text for disk space.
     *
     * If the current plan is custom and it is boosted, display disk space/original plan limits.
     * If the original plan is custom again, then display disk space only.
     * If the current plan is custom and it is NOT boosted, display disk space only.
     * Otherwise, display disk space/current plan limits.
     *
     * NOTE: original/current plan value planDiskLimit (calculated by convertSitePlanDiskLimit)
     *
     * @returns {string} The disk space display text.
     */

    if (currentPlanName === SitePlanEnum.custom) {
      if (isBoosted) {
        if (previousPlanName === SitePlanEnum.custom) {
          return `${localDiskLimit}`;
        }
        return `${localDiskLimit}/${planDiskLimit}`;
      } else {
        return `${localDiskLimit}`;
      }
    } else {
      return `${localDiskLimit}/${planDiskLimit}`;
    }
  }

  return (
    <div className="gv-data-table" style={{ overflowX: 'auto' }}>
      <table>
        <TableHeadCustom headers={HEADERS} />
        <tbody>
          {!isFetching ? (
            <tr>
              <td>{translate(`wpone.sites.plan.${currentPlanName}`)}</td>
              <td>
                {localDiskUsage}/{localDiskLimit} {translate('wpone.general.unit.gb')}
              </td>
              <td>{`${getDiskSpaceDisplayText()} ${translate('wpone.general.unit.gb')}`}</td>
            </tr>
          ) : (
            <TableSkeleton headers={HEADERS} />
          )}
        </tbody>
      </table>
    </div>
  );
}

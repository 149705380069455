import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
// redux
import {
  useLazyGetSiteDebugStatusQuery,
  useActivateSiteDebugStatusMutation,
  useActivateSiteDebugLogMutation,
  useDeactivateSiteDebugStatusMutation,
  useDeactivateSiteDebugLogMutation,
} from 'src/redux/api/siteApi';
// hooks
import useLocales from 'src/hooks/useLocales';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import Toggle from 'src/components/gravity/form/Toggle';
//
import SettingsBlock from './SettingsBlock';

// ----------------------------------------------------------------------

export default function DebugSettings() {
  const { name, cluster, namespace } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  // API
  const [getSiteDebugStatus, { isLoading: debugStatusIsLoading }] =
    useLazyGetSiteDebugStatusQuery();
  const [activateSiteDebug, { isLoading: activateDebugIsLoading }] =
    useActivateSiteDebugStatusMutation();
  const [activateSiteDebugLog, { isLoading: activateLogIsLoading }] =
    useActivateSiteDebugLogMutation();
  const [deactivateSiteDebug, { isLoading: deactivateDebugIsLoading }] =
    useDeactivateSiteDebugStatusMutation();
  const [deactivateSiteDebugLog, { isLoading: deactivateLogIsLoading }] =
    useDeactivateSiteDebugLogMutation();

  // STATE
  const [debugModeErr, setDebugModeErr] = useState<string | null>(null);

  const [toggleDebugMode, setToggleDebugMode] = useState(false);

  // EVENT FUNCTION
  const handleToggleChange = async () => {
    if (!name || !cluster) return;
    try {
      if (toggleDebugMode) {
        const deactivateResponse = await deactivateSiteDebug(`${cluster}/${namespace}`).unwrap();
        if (deactivateResponse.code !== 0) {
          displayToast(
            translate(
              'wponesupport.sites.details.support.debugSettings.deactivateSiteDebug.toast.error.deactivateSiteDebug'
            ),
            { variant: 'alert' }
          );
          return;
        }

        const deactivateLogResponse = await deactivateSiteDebugLog(
          `${cluster}/${namespace}`
        ).unwrap();
        if (deactivateLogResponse.code !== 0) {
          displayToast(
            translate(
              'wponesupport.sites.details.support.debugSettings.deactivateSiteDebug.toast.error.deactivateSiteDebugLog'
            ),
            { variant: 'alert' }
          );
          return;
        }

        if (isMountedRef.current) {
          setToggleDebugMode(false);
        }
        displayToast(
          translate(
            'wponesupport.sites.details.support.debugSettings.deactivateSiteDebug.toast.success'
          )
        );
      } else {
        const activateResponse = await activateSiteDebug(`${cluster}/${namespace}`).unwrap();
        if (activateResponse.code !== 0) {
          displayToast(
            translate(
              'wponesupport.sites.details.support.debugSettings.activateSiteDebug.toast.error.activateSiteDebug'
            ),
            { variant: 'alert' }
          );
          return;
        }

        const activateLogResponse = await activateSiteDebugLog(`${cluster}/${namespace}`).unwrap();
        if (activateLogResponse.code !== 0) {
          displayToast(
            translate(
              'wponesupport.sites.details.support.debugSettings.activateSiteDebug.toast.error.activateSiteDebugLog'
            ),
            { variant: 'alert' }
          );
          return;
        }

        if (isMountedRef.current) {
          setToggleDebugMode(true);
        }
        displayToast(
          translate(
            'wponesupport.sites.details.support.debugSettings.activateSiteDebug.toast.success'
          )
        );
      }
    } catch (error) {
      const errCode = (error as { status: number; data: any }).status;
      displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
    }
  };

  //
  useEffect(() => {
    if (!namespace || !cluster) return;
    getSiteDebugStatus(`${cluster}/${namespace}`)
      .unwrap()
      .then((data) => {
        if (isMountedRef.current) {
          if (data.code === 0 && (data.stdout === 'true' || data.stdout === '"true"')) {
            setToggleDebugMode(true);
          } else {
            setToggleDebugMode(false);
          }
        }
      })
      .catch(() => {
        if (isMountedRef.current) {
          setDebugModeErr(
            translate('wponesupport.sites.details.support.debugSettings.getSiteDebugStatus.error')
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SettingsBlock title={translate('wponesupport.sites.details.support.debugSettings.title')}>
      <Toggle
        condensed
        name="debugMode"
        label={translate('wponesupport.sites.details.support.debugSettings.label')}
        labelId="debugMode"
        description={translate('wponesupport.sites.details.support.debugSettings.description')}
        checked={toggleDebugMode}
        onChange={handleToggleChange}
        disabled={
          debugModeErr !== null ||
          debugStatusIsLoading ||
          activateDebugIsLoading ||
          activateLogIsLoading ||
          deactivateLogIsLoading ||
          deactivateDebugIsLoading
        }
        errMsg={debugModeErr || undefined}
      />
    </SettingsBlock>
  );
}

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import ReactGA from 'react-ga4';
// form
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @types
import { VisualTestingSettings } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// components
import { FormProvider, RHFTextarea } from 'src/components/gravity/hook-form';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';

// ----------------------------------------------------------------------

type FormValuesProps = {
  advanceSettings: string;
  afterSubmit?: string;
};

type Props = {
  currentVisualTestingSettings: VisualTestingSettings;
  setCurrentVisualTestingSettings: Dispatch<SetStateAction<VisualTestingSettings>>;
  setSaveVisualTestingSettingsSuccess: Dispatch<SetStateAction<undefined | boolean>>;
};

// ----------------------------------------------------------------------

export default function AdvanceVisualTestingForm({
  currentVisualTestingSettings,
  setCurrentVisualTestingSettings,
  setSaveVisualTestingSettingsSuccess,
}: Props) {
  const { cluster, namespace, name } = useParams();

  const navigate = useNavigate();

  // HOOK
  const { translate } = useLocales();

  // STATE
  const [isLoading, setisLoading] = useState<boolean>(false);

  // FORM
  const FormSchema = Yup.object().shape({
    advanceSettings: Yup.string()
      .trim()
      .test(
        'advance-settings',
        translate(
          'dashboard.sites.details.mwp.updates.visualUpdateTesting.visualUpdateTestingSection.form.textField.validation'
        ),
        function (value) {
          return validateCSSSelector(value || '');
        }
      ),
  });

  const defaultValues = {
    advanceSettings: currentVisualTestingSettings.advanceSettings,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const { setValue, handleSubmit } = methods;

  // EVENT FUNCTION
  const onSubmit = async (data: FormValuesProps) => {
    if (currentVisualTestingSettings.advanceSettings === data.advanceSettings) return;

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'save-visual-testing-advance-settings',
    });

    setisLoading(true);
    setTimeout(() => {
      setisLoading(false);

      const isSuccess: boolean = true;
      if (isSuccess) {
        setCurrentVisualTestingSettings((prev) => ({
          ...prev,
          advanceSettings: data.advanceSettings,
        }));
      }
      setSaveVisualTestingSettingsSuccess(isSuccess);
    }, 2000);
  };

  // HELPER FUNCTION
  function validateCSSSelector(selectorString: string): boolean {
    try {
      // Split the input string by commas and trim each selector
      const selectors = selectorString.split(',').map((selector) => selector.trim());

      // Validate each selector
      for (const selector of selectors) {
        if (selector) {
          document.querySelector(selector); // Throws if the selector is invalid
        }
      }

      return true; // All selectors are valid
    } catch {
      return false; // At least one selector is invalid
    }
  }

  //
  useEffect(() => {
    setValue('advanceSettings', currentVisualTestingSettings.advanceSettings);
  }, [currentVisualTestingSettings, setValue]);

  return (
    <div className="gv-w-full">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <div className="gv-content-container gv-flex-column-md gv-p-lg">
          <div className="gv-flex-column-sm">
            <p className="gv-text-sm gv-text-bold">
              {translate(
                'dashboard.sites.details.mwp.updates.visualUpdateTesting.visualUpdateTestingSection.form.title'
              )}
            </p>
            <p className="gv-text-sm">
              {translate(
                'dashboard.sites.details.mwp.updates.visualUpdateTesting.visualUpdateTestingSection.form.description'
              )}
            </p>
          </div>

          <RHFTextarea
            name="advanceSettings"
            labelId="advanceSettings"
            label={translate(
              'dashboard.sites.details.mwp.updates.visualUpdateTesting.visualUpdateTestingSection.form.textField.label'
            )}
            placeholder={translate(
              'dashboard.sites.details.mwp.updates.visualUpdateTesting.visualUpdateTestingSection.form.textField.placeholder'
            )}
          />

          <ButtonGroup>
            <Button
              uiType="cancel"
              text={translate('wpone.general.action.back')}
              onClick={() => navigate(`/sites/updates/${cluster}/${namespace}/${name}/settings`)}
            />
            <Button
              type="submit"
              text={translate('dashboard.general.action.save')}
              disabled={isLoading}
            />
          </ButtonGroup>
        </div>
      </FormProvider>
    </div>
  );
}

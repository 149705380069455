import { NavLink as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router';
// redux
import { useSelector } from 'src/redux/store';
// @types
import { UserRoleEnum } from 'src/@types/user';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { isActivePage } from 'src/utils/check';
// config
import { NAV_CONFIG } from 'src/config';
//
import Searchbar from './Searchbar';

// ----------------------------------------------------------------------

type Props = {
  handleOpenDashboardDrawer: VoidFunction;
  handleOpenTabDrawer: VoidFunction;
};

// ----------------------------------------------------------------------

export default function DashboardHeader({ handleOpenDashboardDrawer, handleOpenTabDrawer }: Props) {
  const { pathname } = useLocation();

  // HOOK
  const { translate } = useLocales();

  // STATE
  const { user } = useSelector((state) => state.auth);

  const { tabConfig } = useSelector((state) => state.layout);

  return (
    <div className="gv-activated">
      <header className="gv-main-header">
        <div className="gv-header-nav">
          <div className="gv-nav-left">
            {tabConfig && (
              <button className="gv-btn-menu" onClick={handleOpenTabDrawer}>
                <gv-icon src="/src/icons/menu.svg" />
              </button>
            )}

            <RouterLink to="/" className="gv-logo">
              <gv-logo src="/src/logos/wp-one.svg" />
            </RouterLink>
          </div>
          <nav className="gv-nav-links">
            <ul>
              {(user?.role !== UserRoleEnum.customer
                ? user?.role !== UserRoleEnum.support
                  ? NAV_CONFIG.slice(0, 4)
                  : NAV_CONFIG
                : NAV_CONFIG.slice(0, 2)
              ).map((link, index) => (
                <li
                  key={index}
                  className={
                    isActivePage(`/${link.path.split('/')[1]}`, pathname) ? 'gv-active' : ''
                  }
                >
                  <RouterLink to={link.path}>{translate(link.title)}</RouterLink>
                </li>
              ))}
            </ul>
          </nav>
          <div className="gv-nav-menu">
            <Searchbar />
            <button className="gv-btn-account" onClick={handleOpenDashboardDrawer}>
              <gv-icon src="src/icons/account_circle.svg" />
              <span className="gv-btn-label">{translate('wpone.navbar.account')}</span>
            </button>
          </div>
        </div>
      </header>
    </div>
  );
}

import { CSSProperties, useEffect, useRef } from 'react';

// ----------------------------------------------------------------------

type TextCustomizeProps = {
  text: string;
  textClass?: string;
  linkHref?: string[];
  linkAction?: VoidFunction[];
  //
  textStyle?: CSSProperties;
  strongStyle?: CSSProperties;
  linkStyle?: CSSProperties;
};

// ----------------------------------------------------------------------

export default function TextCustomize({
  text,
  textClass,
  linkHref,
  linkAction,
  //
  textStyle = {},
  strongStyle = {},
  linkStyle = {},
}: TextCustomizeProps) {
  const textRef = useRef<HTMLParagraphElement>(null);

  // HELPER FUNCTION
  function applyStrongStyle(element: HTMLElement, strongStyle: CSSProperties) {
    const strongElements = element.getElementsByTagName('strong');
    for (let i = 0; i < strongElements.length; i++) {
      const strongElement = strongElements[i];
      Object.assign(strongElement.style, strongStyle);
    }
  }

  function applyLinkHrefStyle(element: HTMLElement, linkStyle: CSSProperties, linkHref: string[]) {
    const linkElements = element.getElementsByTagName('a');

    for (let i = 0; i < linkElements.length; i++) {
      const linkElement = linkElements[i];
      linkElement.href = linkHref[i];
      linkElement.target = '_blank';
      Object.assign(linkElement.style, linkStyle);
    }
  }

  function applyLinkActionStyle(
    element: HTMLElement,
    linkStyle: CSSProperties,
    linkAction: VoidFunction[]
  ) {
    const linkElements = element.getElementsByTagName('a');

    for (let i = 0; i < linkElements.length; i++) {
      const linkElement = linkElements[i];
      linkElement.onclick = linkAction[i];
      linkElement.target = '_blank';
      Object.assign(linkElement.style, linkStyle);
    }
  }

  //
  useEffect(() => {
    if (textRef.current) {
      textRef.current.innerHTML = text;
      applyStrongStyle(textRef.current, strongStyle);

      if (linkHref) {
        applyLinkHrefStyle(textRef.current, linkStyle, linkHref);
      }

      if (linkAction) {
        applyLinkActionStyle(textRef.current, linkStyle, linkAction);
      }
    }
  }, [text, linkHref, linkAction, strongStyle, linkStyle]);

  return (
    <p ref={textRef} className={textClass} style={textStyle}>
      {text}
    </p>
  );
}

import { useNavigate, useParams } from 'react-router';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { setUpgradePlanDetails } from 'src/redux/features/plan';
// @types
import { AccessTypeEnum, UserRoleEnum } from 'src/@types/user';
import { SitePlanEnum } from 'src/@types/site';
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
// hooks
import useHighestUserRole from 'src/hooks/useHighestUserRole';
import useLocales from 'src/hooks/useLocales';
// utils
import { isHostnetCustomer } from 'src/utils/check';
// components
import { PageSection } from 'src/components/gravity/page';
//
import StatusResourcesCard from '../StatusResourcesCard';

// ----------------------------------------------------------------------

type Props = {
  site: ListSiteResponseDTO | undefined;
  localDiskLimit: number;
  localDiskUsage: number;
  localDatabaseLimit: number;
  localDatabaseUsage: number;
  planDiskLimit: number;
  //
  onOpenUpgradeDiskDialog: VoidFunction;
  onOpenCustomPlanDialog: VoidFunction;
};

// ----------------------------------------------------------------------

export default function ResourcesBlock({
  site,
  localDiskLimit,
  localDiskUsage,
  localDatabaseLimit,
  localDatabaseUsage,
  planDiskLimit,
  onOpenUpgradeDiskDialog,
  onOpenCustomPlanDialog,
}: Props) {
  const { name, cluster, namespace } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // HOOK
  const { translate } = useLocales();

  const { highestUserRole, isHighestUserRoleLoading } = useHighestUserRole();

  // STATE
  const { user } = useSelector((state) => state.auth);

  // EVENT FUNCTION
  function handleSwitchPlan() {
    if (!site) return;

    dispatch(
      setUpgradePlanDetails({
        name: name as string,
        currentPlan: { name: site.plan.name, billing: site.billing.name },
        currentDiskSpace: localDiskLimit,
        fromTab: 'statistics',
      })
    );
    navigate(`/sites/${cluster}/${namespace}/${name}/change-plan`);
  }

  return (
    <PageSection
      title={translate('wpone.sites.details.statistics.resources.title')}
      description={translate('wpone.sites.details.statistics.resources.description')}
    >
      <div className="gv-flex-column-lg gv-tab-flex-row">
        {/* 
        Support/Sa will see the increase disk option as long as the current local limit < 100
        If local limit >= 100 then no option is displayed
        
        ALL Customer will see the increase disk option as long as local limit < plan limit
        If local limit >= plan limit then ONLY Owner is able to see upgrade plan option
        If (local limit > 80) OR (local limit = 80 BUT current plant IS Enterprise), upgrade plan option will lead to custom plan dialog
        If (local limit < 80) OR (local limit = 80 BUT currnet plan IS NOT Enterprise), upgrade plan option will lead to switch plan page

        NOTE:
        Support/Sa can switch the plan without being restricted by local usage/local limit
        Local usage CANNOT be larger than the local limit
        Local limit CAN be larger than the plan limit (Support/Sa unrestricted with changing plan) 
        */}
        <StatusResourcesCard
          title="wpone.sites.details.statistics.resources.infoCard.title.diskUsage"
          description={translate(
            'wpone.sites.details.statistics.resources.infoCard.description.diskUsage',
            {
              limit: planDiskLimit,
            }
          )}
          resources={{
            usage: localDiskUsage,
            limit: localDiskLimit,
            action: site ? (
              user?.role !== UserRoleEnum.customer ? (
                // Support and SA acc
                localDiskLimit < 100 ? (
                  <a
                    href="/#"
                    className="gv-action"
                    onClick={(e) => {
                      e.preventDefault();
                      onOpenUpgradeDiskDialog();
                    }}
                  >
                    <span
                      className="gv-text-sm gv-text-bold"
                      style={{ color: 'var(--color-state-form-on)' }}
                    >
                      {translate('wpone.sites.infoLink.allocateSpace')}
                    </span>
                  </a>
                ) : undefined
              ) : !isHostnetCustomer(user) &&
                !isHighestUserRoleLoading &&
                site.plan.name !== SitePlanEnum.custom ? (
                // Customer acc
                localDiskLimit < planDiskLimit ? (
                  <a
                    href="/#"
                    className="gv-action"
                    onClick={(e) => {
                      e.preventDefault();
                      onOpenUpgradeDiskDialog();
                    }}
                  >
                    <span
                      className="gv-text-sm gv-text-bold"
                      style={{ color: 'var(--color-state-form-on)' }}
                    >
                      {translate('wpone.sites.infoLink.allocateSpace')}
                    </span>
                  </a>
                ) : highestUserRole === AccessTypeEnum.owner ? (
                  <a
                    href="/#"
                    className="gv-action"
                    onClick={(e) => {
                      e.preventDefault();
                      if (
                        (site.plan.name === SitePlanEnum.enterprise && localDiskLimit === 80) ||
                        localDiskLimit > 80
                      ) {
                        onOpenCustomPlanDialog();
                      } else {
                        handleSwitchPlan();
                      }
                    }}
                  >
                    <span
                      className="gv-text-sm gv-text-bold"
                      style={{ color: 'var(--color-state-form-on)' }}
                    >
                      {translate('wpone.sites.infoLink.upgradePlan')}
                    </span>
                  </a>
                ) : undefined
              ) : undefined
            ) : undefined,
          }}
        />

        <StatusResourcesCard
          title="wpone.sites.details.statistics.resources.infoCard.title.dbSize"
          description="wpone.sites.details.statistics.resources.infoCard.description.dbSize"
          resources={{
            usage: localDatabaseUsage,
            limit: localDatabaseLimit,
          }}
        />
      </div>
    </PageSection>
  );
}

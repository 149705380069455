import { Dispatch, SetStateAction, useState } from 'react';
import ReactGA from 'react-ga4';
// @types
import {
  CoreUpdatesEnum,
  CustomScheduleDayTimeEnum,
  CustomScheduleFrequencyEnum,
  CustomScheduleWeekTimeEnum,
  UpdateSettings,
} from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { displayToast } from 'src/utils/handleToast';
import { getAllTimeZones } from 'src/utils/getTimeZones';
// mock
import { DUMMY_INCLUDED_AUTO_UPDATES_SETTINGS_DATA } from 'src/mock/sites';
// components
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';

// ----------------------------------------------------------------------

type Props = {
  onClose: VoidFunction;
  setCurrentSettings: Dispatch<SetStateAction<UpdateSettings>>;
};

// ----------------------------------------------------------------------

export default function ResetSettingsConfirmation({ onClose, setCurrentSettings }: Props) {
  // STATE
  const [isLoading, setisLoading] = useState<boolean>(false);

  // HOOK
  const { translate } = useLocales();

  // EVENT FUNCTION
  async function handleResetSettings() {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'reset-update-settings',
    });

    setisLoading(true);
    try {
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve('Fetch successful');
          // reject('Fetch failed');
        }, 3000);
      });

      const isSuccess: boolean = true;

      if (isSuccess) {
        displayToast(
          translate(
            'dashboard.sites.details.mwp.updates.updateSettings.resetSettingsDialog.toast.success'
          )
        );
        setCurrentSettings({
          autoUpdate: {
            activate: true,
            visualTesting: {
              activate: true,
              advanceSettings: '',
            },
            customSchedule: {
              activate: false,
              settings: {
                frequency: CustomScheduleFrequencyEnum.WEEKLY,
                timeOfWeek: CustomScheduleWeekTimeEnum.WEEKDAYS,
                timeOfDay: CustomScheduleDayTimeEnum.MORNING,
                timeZone: getAllTimeZones()[0],
              },
            },
            pluginThemeUpdates: DUMMY_INCLUDED_AUTO_UPDATES_SETTINGS_DATA,
          },
          core: CoreUpdatesEnum.ALL,
          notifications: {
            failedUpdates: true,
            successfulUpdates: true,
          },
        });
      } else {
        displayToast(
          translate(
            'dashboard.sites.details.mwp.updates.updateSettings.resetSettingsDialog.toast.error'
          ),
          {
            variant: 'alert',
          }
        );
      }
    } finally {
      setisLoading(false);
      onClose();
    }
  }

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate(
            'dashboard.sites.details.mwp.updates.updateSettings.resetSettingsDialog.title'
          )}
        </h2>

        <div style={{ overflowX: 'auto' }}>
          <div className="gv-flex-column-sm">
            <p>
              {translate(
                'dashboard.sites.details.mwp.updates.updateSettings.resetSettingsDialog.description'
              )}
            </p>
          </div>
        </div>
      </div>

      <ButtonGroup>
        <Button
          text={translate('dashboard.general.action.cancel')}
          uiType="cancel"
          onClick={onClose}
        />
        <Button
          text={translate(
            'dashboard.sites.details.mwp.updates.updateSettings.resetSettingsDialog.action'
          )}
          onClick={handleResetSettings}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}

// @types
import { TabNavLinkDetails, SiteTabNavLinkDetails } from 'src/@types/layout';
import { AccessTypeEnum, UserRoleEnum } from 'src/@types/user';
import { UserDTO } from '@joonasvanhatapio/wp-cloud-backend-types';
//
import { isHostnetCustomer } from './check';

// ----------------------------------------------------------------------

// Only filter for 403 restriction
export function filterAllowedSiteDetailsTabs(
  navConfig: SiteTabNavLinkDetails[],
  user: UserDTO,
  highestUserRole: AccessTypeEnum
): TabNavLinkDetails[] {
  return (
    navConfig
      // Filter user role
      .filter((nav) => {
        if (nav.restrictedUserRoles) return !nav.restrictedUserRoles.includes(user.role);
        return true;
      })
      // Filter site role
      .filter((nav) => {
        if (user.role === UserRoleEnum.customer && nav.restrictedSiteRoles)
          return !nav.restrictedSiteRoles.includes(highestUserRole);
        return true;
      })
      // Filter Hostnet customer
      .filter((nav) => {
        if (nav.restrictedForHostnetCustomer) return isHostnetCustomer(user) === false;
        return true;
      })
      .map((nav) => ({ title: nav.title, tabName: nav.tabName, icon: nav.icon }))
  );
}

import { Dispatch, SetStateAction, useState } from 'react';
import ReactGA from 'react-ga4';
// @types
import { UpdateSettings } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// components
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import { displayToast } from 'src/utils/handleToast';

// ----------------------------------------------------------------------

type Props = {
  onClose: VoidFunction;
  setCurrentSettings: Dispatch<SetStateAction<UpdateSettings>>;
};

// ----------------------------------------------------------------------

export default function DeactivateAutoUpdatesConfirmation({ onClose, setCurrentSettings }: Props) {
  // STATE
  const [isLoading, setisLoading] = useState<boolean>(false);

  // HOOK
  const { translate } = useLocales();

  // EVENT FUNCTION
  async function handleDeactivate() {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'deactivate-auto-updates',
    });

    setisLoading(true);
    try {
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve('Fetch successful');
          // reject('Fetch failed');
        }, 3000);
      });

      const isSuccess: boolean = true;

      if (isSuccess) {
        displayToast(
          translate(
            'dashboard.sites.details.mwp.updates.updateSettings.autoUpdatesSection.deactivateAutoUpdateDialog.toast.success'
          )
        );
        setCurrentSettings((prev) => ({
          ...prev,
          autoUpdate: {
            ...prev.autoUpdate,
            activate: false,
          },
        }));
      } else {
        displayToast(
          translate(
            'dashboard.sites.details.mwp.updates.updateSettings.autoUpdatesSection.deactivateAutoUpdateDialog.toast.error'
          ),
          {
            variant: 'alert',
          }
        );
      }
    } finally {
      setisLoading(false);
      onClose();
    }
  }

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate(
            'dashboard.sites.details.mwp.updates.updateSettings.autoUpdatesSection.deactivateAutoUpdateDialog.title'
          )}
        </h2>

        <div style={{ overflowX: 'auto' }}>
          <div className="gv-flex-column-sm">
            <p>
              {translate(
                'dashboard.sites.details.mwp.updates.updateSettings.autoUpdatesSection.deactivateAutoUpdateDialog.description'
              )}
            </p>
          </div>
        </div>
      </div>

      <ButtonGroup>
        <Button
          text={translate('dashboard.general.action.cancel')}
          uiType="cancel"
          onClick={onClose}
        />
        <Button
          text={translate(
            'dashboard.sites.details.mwp.updates.updateSettings.autoUpdatesSection.deactivateAutoUpdateDialog.action'
          )}
          onClick={handleDeactivate}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}

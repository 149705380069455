import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
// form
import { useForm } from 'react-hook-form';
// @types
import { UpdateSettings } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// components
import { FormProvider, RHFCheckbox } from 'src/components/gravity/hook-form';
import Button from 'src/components/gravity/Button';
import Alert from 'src/components/gravity/Alert';
//
import SettingsSection from '../SettingsSection';

// ----------------------------------------------------------------------

type UpdateNotifications = {
  failedUpdates: boolean;
  successfulUpdates: boolean;
};

type FormValuesProps = UpdateNotifications;

type Props = {
  currentSettings: UpdateSettings;
  setCurrentSettings: Dispatch<SetStateAction<UpdateSettings>>;
};

// ----------------------------------------------------------------------

export default function UpdateNotificationsSection({ currentSettings, setCurrentSettings }: Props) {
  // HOOK
  const { translate } = useLocales();

  // STATE
  const [isLoading, setisLoading] = useState<boolean>(false);

  const [saveNotificationsSettingsSuccess, setSaveNotificationsSettingsSuccess] = useState<
    boolean | undefined
  >(undefined);

  // FORM
  const defaultValues = {
    failedUpdates: currentSettings.notifications.failedUpdates,
    successfulUpdates: currentSettings.notifications.successfulUpdates,
  };

  const methods = useForm<FormValuesProps>({
    defaultValues,
  });

  const { setValue, handleSubmit } = methods;

  //EVENT FUNCTION
  const onSubmit = async (data: FormValuesProps) => {
    if (
      currentSettings.notifications.failedUpdates === data.failedUpdates &&
      currentSettings.notifications.successfulUpdates === data.successfulUpdates
    )
      return;

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'save-update-notifications-settings',
    });

    setisLoading(true);
    setTimeout(() => {
      setisLoading(false);

      const isSuccess: boolean = true;

      if (isSuccess) {
        setCurrentSettings((prev) => ({
          ...prev,
          notifications: data,
        }));
      }
      setSaveNotificationsSettingsSuccess(isSuccess);
    }, 2000);
  };

  //
  useEffect(() => {
    setValue('failedUpdates', currentSettings.notifications.failedUpdates);
    setValue('successfulUpdates', currentSettings.notifications.successfulUpdates);
  }, [currentSettings, setValue]);

  return (
    <SettingsSection
      title={translate(
        'dashboard.sites.details.mwp.updates.updateSettings.updateNotificationsSection.title'
      )}
      description={translate(
        'dashboard.sites.details.mwp.updates.updateSettings.updateNotificationsSection.description'
      )}
      alert={
        saveNotificationsSettingsSuccess !== undefined ? (
          <Alert
            type={saveNotificationsSettingsSuccess ? 'success' : 'alert'}
            text={translate(
              `dashboard.sites.details.mwp.updates.updateSettings.updateNotificationsSection.alert.${
                saveNotificationsSettingsSuccess ? 'success' : 'error'
              }`
            )}
            handleClose={() => setSaveNotificationsSettingsSuccess(undefined)}
          />
        ) : undefined
      }
    >
      <div className="gv-w-full">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div className="gv-content-container gv-flex-column-md gv-p-lg">
            <div className="gv-flex-column-sm">
              <p className="gv-text-sm gv-text-bold">
                {translate(
                  'dashboard.sites.details.mwp.updates.updateSettings.updateNotificationsSection.form.title'
                )}
              </p>
              <div className="gv-flex-column-md">
                <RHFCheckbox
                  name="failedUpdates"
                  label={translate(
                    'dashboard.sites.details.mwp.updates.updateSettings.updateNotificationsSection.form.checkboxes.failed'
                  )}
                  labelId="failedUpdates"
                  condensed
                />
                <RHFCheckbox
                  name="successfulUpdates"
                  label={translate(
                    'dashboard.sites.details.mwp.updates.updateSettings.updateNotificationsSection.form.checkboxes.successful'
                  )}
                  labelId="successfulUpdates"
                  condensed
                />
              </div>
            </div>

            <div>
              <Button
                type="submit"
                text={translate('dashboard.general.action.save')}
                disabled={isLoading}
              />
            </div>
          </div>
        </FormProvider>
      </div>
    </SettingsSection>
  );
}

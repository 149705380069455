import { createApi } from '@reduxjs/toolkit/query/react';
// utils
import { createSignedAuthHeader } from 'src/utils/axiosAuth';
//
import { axiosBaseQuery } from './axiosBaseQuery';

// ----------------------------------------------------------------------

const REACT_APP_CHAT_BOT_AGENT_ID = '1ecdfae7-e907-4823-a17e-4f12ec289146';

// ----------------------------------------------------------------------

export const chatBotApi = createApi({
  reducerPath: 'chatBotApi',
  baseQuery: axiosBaseQuery({
    baseUrl: 'https://api.synohq.com/v0',
  }),
  tagTypes: ['chat-bot'],
  endpoints: (builder) => ({
    // Get agent
    getAgent: builder.query<
      {
        id: string;
        name: string;
        prompt: string;
        model: string;
        channel: string;
        keyOrigin: string;
        language: string;
        temperature: number;
        createdAt: string;
        updatedAt: string;
        deletedAt: string | null;
      },
      {
        webspaceToken: string;
      }
    >({
      query: ({ webspaceToken }) => ({
        url: `/agent/${REACT_APP_CHAT_BOT_AGENT_ID}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: createSignedAuthHeader(`/v0/agent/${REACT_APP_CHAT_BOT_AGENT_ID}`, 'GET', {
            'webspace-api-token': webspaceToken,
          }).authHeader,
        },
        method: 'get',
      }),
    }),
    // Start new session
    startSession: builder.mutation<
      {
        sessionId: string;
        response: string;
      },
      {
        webspaceToken: string;
        siteId: string;
        message: string;
      }
    >({
      query: ({ webspaceToken, siteId, message }) => ({
        url: `/session/${REACT_APP_CHAT_BOT_AGENT_ID}`,
        headers: {
          Authorization: createSignedAuthHeader(
            `/v0/session/${REACT_APP_CHAT_BOT_AGENT_ID}`,
            'POST',
            {
              'webspace-api-token': webspaceToken,
            }
          ).authHeader,
        },
        method: 'post',
        data: {
          message: `The current installation id is ${siteId}. ${message}`,
        },
      }),
    }),
    // Emit message
    emitMessage: builder.mutation<
      {
        success: boolean;
        message: {
          response: string;
        };
      },
      {
        webspaceToken: string;
        sessionId: string;
        content: string;
      }
    >({
      query: ({ webspaceToken, sessionId, content }) => ({
        url: `/message/${sessionId}`,
        headers: {
          Authorization: createSignedAuthHeader(`/v0/message/${sessionId}`, 'POST', {
            'webspace-api-token': webspaceToken,
          }).authHeader,
        },
        method: 'post',
        data: {
          content,
        },
      }),
    }),
    // End session
    endSession: builder.mutation<
      {
        id: string; // Session ID
        state: null;
        createdAt: string;
        endedAt: string;
      },
      {
        webspaceToken: string;
        sessionId: string;
      }
    >({
      query: ({ webspaceToken, sessionId }) => ({
        url: `/session/${sessionId}`,
        headers: {
          Authorization: createSignedAuthHeader(`/v0/session/${sessionId}`, 'DELETE', {
            'webspace-api-token': webspaceToken,
          }).authHeader,
        },
        method: 'delete',
      }),
    }),
  }),
});

export const {
  useLazyGetAgentQuery,
  useStartSessionMutation,
  useEmitMessageMutation,
  useEndSessionMutation,
} = chatBotApi;

import mixpanel from 'mixpanel-browser';

// ----------------------------------------------------------------------

const isProduction = window.location.href.startsWith('https://app.wp.one/');
const token = isProduction
  ? 'e1135bbed811a82645a7df564f0278c4'
  : 'ca194771e8caa6fca7ff02896cded17d';

export default function initMixpanel() {
  if (!token) {
    console.warn('Mixpanel token is missing! Check your .env file.');
    return;
  }
  const config = {
    api_host: 'https://app.wp.one/mix',
    autocapture: false,
  };
  mixpanel.init(token, config);
}

import { AnyAction, combineReducers, Reducer } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// api
import { authApi } from './api/authApi';
import { siteApi } from './api/siteApi';
import { resourceApi } from './api/resourceApi';
import { updateApi } from './api/updateApi';
import { domainApi } from './api/domainApi';
import { userApi } from './api/userApi';
import { wpInstallOptionsApi } from './api/wpInstallOptionsApi';
import { migrationApi } from './api/migrationApi';
import { backupApi } from './api/backupApi';
import { wordpressApi } from './api/wordpressApi';
import { wpCredentialsMigrationApi } from './api/wpCredentialsMigrationApi';
import { historyApi } from './api/historyApi';
import { chatBotApi } from './api/chatBotApi';
// features
import layoutReducer from './features/layout';
import authReducer, { setUser } from './features/auth';
import siteReducer from './features/site';
import domainReducer from './features/domain';
import migrationReducer from './features/migration';
import backupReducer from './features/backup';
import planReducer from './features/plan';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const layoutPersistConfig = {
  key: 'layout',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['showActionsInProgressBanner', 'searchHistory'],
};

const authPersistConfig = {
  key: 'auth',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['user'],
};

const sitePersistConfig = {
  key: 'site',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['selectedCols', 'unselectedCol', 'showSubScoreDescription', 'updatingSites'],
};

const domainPersistConfig = {
  key: 'domain',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const migrationPersistConfig = {
  key: 'migration',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['migrationsInProgress'],
};

const backupPersistConfig = {
  key: 'backup',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['backupsInProgress'],
};

const planPersistConfig = {
  key: 'plan',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const combinedReducer = combineReducers({
  // features
  layout: persistReducer(layoutPersistConfig, layoutReducer),
  auth: persistReducer(authPersistConfig, authReducer),
  site: persistReducer(sitePersistConfig, siteReducer),
  domain: persistReducer(domainPersistConfig, domainReducer),
  migration: persistReducer(migrationPersistConfig, migrationReducer),
  backup: persistReducer(backupPersistConfig, backupReducer),
  plan: persistReducer(planPersistConfig, planReducer),

  // api
  [authApi.reducerPath]: authApi.reducer,
  [siteApi.reducerPath]: siteApi.reducer,
  [resourceApi.reducerPath]: resourceApi.reducer,
  [updateApi.reducerPath]: updateApi.reducer,
  [domainApi.reducerPath]: domainApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [wpInstallOptionsApi.reducerPath]: wpInstallOptionsApi.reducer,
  [migrationApi.reducerPath]: migrationApi.reducer,
  [backupApi.reducerPath]: backupApi.reducer,
  [wordpressApi.reducerPath]: wordpressApi.reducer,
  [wpCredentialsMigrationApi.reducerPath]: wpCredentialsMigrationApi.reducer,
  [historyApi.reducerPath]: historyApi.reducer,
  [chatBotApi.reducerPath]: chatBotApi.reducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action === setUser && action.payload === null) {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export { rootPersistConfig, rootReducer };
